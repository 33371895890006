// Angular Components
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Local Components
import { AgePipe } from 'src/app/shared/pipes/age.pipe';
import { AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { AppComponent } from 'src/app/app.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { AppointmentSearchBarComponent } from 'src/app/components/appointment-search-bar/appointment-search-bar.component';
import { AppointmentSearchTableComponent } from 'src/app/components/appointment-search-table/appointment-search-table.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AwaitPopoverComponent } from 'src/app/components/common/popovers/await-popover/await-popover.component';
import { ChatInfoPanelComponent } from 'src/app/components/chat-info-panel/chat-info-panel.component';
import { ChatPageMenuComponent } from 'src/app/components/chat-page-menu/patient-chat-header.component';
import { ConversationCardComponent } from 'src/app/components/conversation-card/conversation-card.component';
import { ConversationListComponent } from 'src/app/components/conversation-list/conversation-list.component';
import { ConversationTimestampPipe } from 'src/app/shared/pipes/conversation-timestamp.pipe';
import { ConversationWindowComponent } from 'src/app/components/conversation-window/conversation-window.component';
import { ConversationWindowHeaderComponent } from 'src/app/components/conversation-window-header/conversation-window-header.component';
import { CreateChatComponent } from 'src/app/components/create-chat/create-chat.component';
import { CreateMessageOptionsComponent } from 'src/app/components/create-message-options/create-message-options.component';
import { DatePickerComponent } from 'src/app/components/common/picker-components/date-picker/date-picker.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { NamePipe } from 'src/app/shared/pipes/name.pipe';
import { PaginationComponent } from 'src/app/components/common/pagination/pagination.component';
import { PatientChatComponent } from 'src/app/components/patient-chat/patient-chat.component';
import { ReturnButtonComponent } from 'src/app/components/common/return-button/return-button.component';
import { ComboSelectorComponent } from 'src/app/components/common/picker-components/combo-selector/combo-selector.component';
import { SingleSelectorComponent } from './components/common/picker-components/single-selector/single-selector.component';
import { SortAppointmentsPipe } from 'src/app/shared/pipes/sort-appointments.pipe';
import { SortChatPipe } from 'src/app/shared/pipes/sort-chat.pipe';
import { SubmitButtonComponent } from 'src/app/components/common/submit-button/submit-button.component';
import { TextInputComponent } from 'src/app/components/common/text-input/text-input.component';
import { TimezonePipe } from 'src/app/shared/pipes/timezone.pipe';
import { ToolTipComponent } from 'src/app/components/common/tooltip/tooltip.component';

// injection tokens
import { HOST_TARGET_TOKEN, MESSAGE_SOURCE_TOKEN } from 'src/app/services/session-info.service';
import { environment } from 'src/environments/environment';
import { IconService } from './services/icon.service';

@NgModule({
    declarations: [
        AgePipe,
        AlertPopoverComponent,
        AppComponent,
        AppointmentSearchBarComponent,
        AppointmentSearchTableComponent,
        AwaitPopoverComponent,
        ChatInfoPanelComponent,
        ChatPageMenuComponent,
        ConversationCardComponent,
        ConversationListComponent,
        ConversationTimestampPipe,
        ConversationWindowComponent,
        ConversationWindowHeaderComponent,
        CreateChatComponent,
        CreateChatComponent,
        CreateMessageOptionsComponent,
        DatePickerComponent,
        ChatMessageComponent,
        NamePipe,
        PaginationComponent,
        PatientChatComponent,
        PatientChatComponent,
        ReturnButtonComponent,
        ComboSelectorComponent,
        SingleSelectorComponent,
        SortAppointmentsPipe,
        SortChatPipe,
        SubmitButtonComponent,
        TextInputComponent,
        TimezonePipe,
        ToolTipComponent,
    ],
    imports: [
        AppRoutingModule,
        AppMaterialModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
    ],
    providers: [
        // we can't just inject window.parent because angular will try to check for other properties, and then
        // throw an error from cross origin access.   So wrap the object and only use the one function.
        {
            provide: HOST_TARGET_TOKEN,
            useValue: { postMessage: environment.hostMessageTarget.postMessage.bind(environment.hostMessageTarget) }
        },
        { provide: MESSAGE_SOURCE_TOKEN, useValue: window },
        IconService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

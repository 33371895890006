import { Injectable } from '@angular/core';
import { ConversationCard } from 'src/app/shared/interfaces/conversation-card.interface';
import { BehaviorSubject, Observable, Subject, Subscriber, Subscription, concatMap, from } from 'rxjs';
import { ApiPaths, PatientChatPaths } from 'src/app/shared/enums/api-paths.enum';
import { environment } from 'src/environments/environment';
import { CareNarrativeServiceBase } from './care-narrative-service-base.service';
import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { GetPatientChatRequest, GetPatientChatResult } from 'src/app/shared/interfaces/get-chats-request.interface';
import { ChatSummaryEvent, ChatSummaryEventType } from 'src/app/shared/interfaces/chat-summary-event.interface';
import { HttpClient } from '@angular/common/http';
import { SessionInfoService } from './session-info.service';
import { PatientChatCardUpdate } from '../shared/interfaces/patient-chat-card-update.interface';

@Injectable({
    providedIn: 'root'
})
export class ChatSummaryService extends CareNarrativeServiceBase implements DataSource<ConversationCard> {

    public get length() {
        return this.cachedData.length;
    }

    public get selectedConversation(): ConversationCard {
        return this.cachedData.find(chat => chat && chat.isSelected) as ConversationCard;
    }

    public serviceContext: GetPatientChatRequest = {
        dateFrom: undefined,
        dateTo: undefined,
        isOpen: undefined,
        isUnread: undefined,
        patientId: undefined,
        phoneNumber: undefined,
        facilityIds: undefined,
        providerIds: undefined,
        suborganizationId: undefined,
        appointmentTypes: undefined,
        userId: undefined,
        skip: 0,
        take: ChatSummaryService.pageSize,
    };

    private cachedData: (ConversationCard)[] = [];

    private currentEndPage?: number;

    private currentStartPage?: number;

    public loading = true;

    public readonly dataStream = new BehaviorSubject<(ConversationCard)[]>(this.cachedData);

    private static readonly pagesToBuffer = 1;

    private static readonly pageSize = 50;

    private readonly signalRSubscription = new Subscription();

    private readonly scrollingSubscription = new Subscription();

    private summaryUpdateSubject = new Subject<ChatSummaryEvent> ();

    constructor(
        protected override http: HttpClient,
        protected override sessionInfoService: SessionInfoService
    ) {
        super(http, sessionInfoService);
        // keeps calls to update sequential instead of concurrent.
        this.signalRSubscription.add(
            this.summaryUpdateSubject.pipe(concatMap(
                (request: ChatSummaryEvent) => this.processUpdate(request))
            ).subscribe()
        );
        this.signalRSubscription.add(
            this.sessionInfoService.PatientChatCardUpdate.subscribe(
                (request: PatientChatCardUpdate) => this.scheduleCardUpdate(request)
            )
        );
        this.sessionLoaded.subscribe( (loaded: boolean) => {
            if (loaded) {
                this.processRangeRequest({start: 1, end: ChatSummaryService.pageSize - 1})
                    .subscribe(() => this.loading = false);
            }
        });
    }

    // DataSource function implementation. Called onInit when datasource is attached to a scrolling solution
    public connect(collectionViewer: CollectionViewer): Observable<(ConversationCard)[]> {
        this.scrollingSubscription.add(
            this.sessionLoaded.subscribe( (loaded: boolean) => {
                if (loaded) {
                    this.currentStartPage = undefined;
                    this.currentEndPage = undefined;
                    this.processRangeRequest({start: 1, end: ChatSummaryService.pageSize - 1}).subscribe();
                    // Changes to CollectionViewer are pushed when new elements enter or leave the UI view.
                    this.scrollingSubscription.add(
                        collectionViewer.viewChange.subscribe(range => {
                            const event: ChatSummaryEvent = {
                                eventType: ChatSummaryEventType.ScrollingUpdate,
                                data: range,
                            };
                            this.summaryUpdateSubject.next(event);
                        }),
                    );
                }
            })
        );

        return this.dataStream;
    }

    // DataSource function implementation. Called onDestroy when attached to a scrolling solution
    public disconnect(): void {
        this.scrollingSubscription.unsubscribe();
    }

    public setSelectedAsRead(): void {
        if(this.selectedConversation) {
            this.selectedConversation.isUnread = false;
        }
    }

    private dropPageFromIndex(startPage: number, endPage: number): Observable<any> {
        return new Observable((subscriber: Subscriber<any>) => {

            const pages = Math.abs(endPage - startPage);
            const blanks = Array.from<ConversationCard>({ length: ChatSummaryService.pageSize * pages });
            this.cachedData.splice(
                ChatSummaryService.pageSize * startPage,
                ChatSummaryService.pageSize * pages,
                ...blanks,
            );

            this.dataStream.next(this.cachedData);

            subscriber.next();
            subscriber.complete();
        });
    }

    private fetchPage(request: GetPatientChatRequest, startIndex: number, pageSize: number = ChatSummaryService.pageSize): Observable<any> {

        // New object so sequential calls don't overwrite page numbers.
        const newRequest: GetPatientChatRequest = {
            dateFrom: request.dateFrom,
            dateTo: request.dateTo,
            isOpen: request.isOpen,
            isUnread: request.isUnread,
            patientId: request.patientId,
            phoneNumber: request.phoneNumber,
            facilityIds: request.facilityIds,
            providerIds: request.providerIds,
            suborganizationId: request.suborganizationId,
            appointmentTypes: request.appointmentTypes,
            userId: request.userId,
            skip: startIndex,
            take: pageSize,
        };

        return new Observable((subscriber: Subscriber<any>) => {
            // Populate section of the data array with the retrieved data.
            this.getConversationList(newRequest).subscribe(result => {
                if (result === undefined) {
                    throw new Error('Unable to retreive specified appointment page');
                } else if (result.responses.length > 0) {
                    const latestCards = result.responses;
                    this.cachedData.splice(
                        startIndex,
                        latestCards.length,
                        ...latestCards,
                    );
                    this.dataStream.next(this.cachedData);
                } else {
                    this.dataStream.next(this.cachedData);
                }
            });

            subscriber.next();
            subscriber.complete();
        });
    }

    public getConversationList(request: GetPatientChatRequest): Observable<GetPatientChatResult | undefined> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}${PatientChatPaths.ChatSummary.replace('{clientId}', this.sessionInfo.ClientId)}`;
        return this.http.post<GetPatientChatResult | undefined>(url, request, this.options);
    }

    private getPageForIndex(index: number): number {
        return Math.floor(index / ChatSummaryService.pageSize);
    }

    private processUpdate(event: ChatSummaryEvent): Observable<ChatSummaryEvent>{
        return new Observable((subscriber: Subscriber<any>) => {
            switch(event.eventType){
                case ChatSummaryEventType.ScrollingUpdate:
                    this.processRangeRequest(event.data as ListRange).subscribe({
                        complete: () => {
                            subscriber.next(event);
                            subscriber.complete();
                        }
                    });
                    break;
                case ChatSummaryEventType.IncrementalUpdate:
                    this.processCardUpdate(event.data as PatientChatCardUpdate).subscribe({
                        complete: () => {
                            subscriber.next(event);
                            subscriber.complete();
                        }
                    });
                    break;
            }
        });
    }

    private processCardUpdate(update: PatientChatCardUpdate): Observable<any> {
        return new Observable((subscriber: Subscriber<any>) => {
            const matchingCard = this.cachedData.find(x => x?.conversationId === update.conversationId);
            if (matchingCard){
                matchingCard.isOpen = update.isOpen;
                matchingCard.isUnread = !update.isRead;
                matchingCard.previewMessage = update.previewMessage;
                matchingCard.lastUpdated = update.messageTime;
                matchingCard.errorState = update.errorState;
                matchingCard.resumeCode = update.resumeCode;
            }

            this.dataStream.next(this.cachedData);
            subscriber.complete();
        });
    }

    private processRangeRequest (range: ListRange): Observable<any> {
        return new Observable((subscriber: Subscriber<any>) => {
            // Determine page range to have in context
            const endPage = this.getPageForIndex(range.end - 1) + ChatSummaryService.pagesToBuffer;
            let startPage = this.getPageForIndex(range.start) - ChatSummaryService.pagesToBuffer;
            if (startPage < 1) {
                startPage = 0;
            }

            // On first load, load in page 1 and any downpage buffer needed
            if (this.currentStartPage === undefined || this.currentEndPage === undefined) {
                this.fetchPage(this.serviceContext, 0, ChatSummaryService.pageSize * (ChatSummaryService.pagesToBuffer + 1)).subscribe({
                    complete: () => {
                        // Update current page
                        this.currentStartPage = startPage;
                        this.currentEndPage = endPage;

                        subscriber.next();
                        subscriber.complete();
                    }
                });
            } else {
                /* eslint-disable @typescript-eslint/no-non-null-assertion */
                from([null]).pipe(
                    concatMap(() => new Observable<any>((dropSubscriber: Subscriber<any>) => {
                        // Drop pages that are not in the current context
                        if ( this.currentStartPage! < startPage ) {
                            this.dropPageFromIndex(this.currentStartPage!, startPage).subscribe({
                                complete: () => {
                                    dropSubscriber.next();
                                    dropSubscriber.complete();
                                }
                            });
                        } else {
                            dropSubscriber.next();
                            dropSubscriber.complete();
                        }
                    })),
                    concatMap(() => new Observable<any>((dropSubscriber: Subscriber<any>) => {
                        if ( this.currentEndPage! > endPage ) {
                            this.dropPageFromIndex(endPage, this.currentEndPage!).subscribe({
                                complete: () => {
                                    dropSubscriber.next();
                                    dropSubscriber.complete();
                                }
                            });
                        } else {
                            dropSubscriber.next();
                            dropSubscriber.complete();
                        }
                    })),
                    concatMap(() => new Observable<any>((addSubscriber: Subscriber<any>) => {
                        // Fetch pages that are entering current context
                        if ( this.currentStartPage! > startPage ) {
                            this.fetchPage(
                                this.serviceContext,
                                startPage * ChatSummaryService.pageSize,
                                (this.currentStartPage! - startPage) * ChatSummaryService.pageSize
                            ).subscribe({
                                complete: () => {
                                    addSubscriber.next();
                                    addSubscriber.complete();
                                }
                            });
                        } else {
                            addSubscriber.next();
                            addSubscriber.complete();
                        }
                    })),
                    concatMap(() => new Observable<any>((addSubscriber: Subscriber<any>) => {
                        // Fetch pages that are entering current context
                        if ( this.currentEndPage! < endPage ){
                            this.fetchPage(
                                this.serviceContext,
                                (this.currentEndPage! + 1) * ChatSummaryService.pageSize,
                                (endPage - this.currentEndPage!) * ChatSummaryService.pageSize
                            ).subscribe({
                                complete: () => {
                                    addSubscriber.next();
                                    addSubscriber.complete();
                                }
                            });
                        } else {
                            addSubscriber.next();
                            addSubscriber.complete();
                        }
                    })),
                ).subscribe({
                    complete: () => {
                        subscriber.next();
                        subscriber.complete();
                    }
                });
            }
        });
    }

    private scheduleCardUpdate(update: PatientChatCardUpdate){
        const event: ChatSummaryEvent = {
            eventType: ChatSummaryEventType.IncrementalUpdate,
            data: update,
        };
        this.summaryUpdateSubject.next(event);
    }
}

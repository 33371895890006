<div class="conversation-window-wrapper chat-panel">
    <div [ngClass]="'conversation-stack'">
        <ng-container *ngIf="chat; else noChat">
            <app-conversation-window-header [chatInfo]="chat"></app-conversation-window-header>
            <app-chat-message id="chatMessages" [ngClass]="'chat-wrapper'" [conversation]="chat"></app-chat-message>
            <ng-container *ngIf="chat && chat.isOpen">
                <text-input
                    [ngClass]="'message-window'"
                    [placeholderText] = "'Type a new message'"
                    [textlines] = 4
                    [(value)]="message"
                    [characterLimit] = 2000/>
                
                <div [ngClass]="'send-button-row'">
                    <div *ngIf="errorState" class="criteria-error-text">
                        {{errorMessage}}
                    </div>
                    <img [ngClass]="'send-button'" (click)="sendMessage()" src="assets/icon/Send-Arrow.svg">
                </div>
            </ng-container>
        </ng-container>
        <ng-template #noChat>
            <div class="no-chat-wrapper">
                <ng-container *ngIf="!loading; else loadingSpinner">
                    <h2>No Chat Selected</h2>
                    <p>Select a chat to read.</p>
                </ng-container>

                <ng-template #loadingSpinner>
                    <mat-spinner></mat-spinner>
                </ng-template>
            </div>
        </ng-template>
    </div>
</div>

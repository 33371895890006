<div class="search-criteria">
    <div class="criteria-header"> {{criteriaTitle}} </div>
    <mat-select multiple panelClass="criteria-dropdown-panel"
        (blur) = 'setErrorState()'
        (selectionChange) = 'setErrorState()'
        [ngClass]="errorState ? 'criteria-dropdown criteria-error':'criteria-dropdown'"
        [formControl]="form"
        [placeholder]="placeholderText">
        <mat-select-trigger>
            <ng-template [ngIf]="form.value !== null && form.value.length > 1 && form.value.length < searchOptions.length">
                {{form.value.length}} {{plural}}
            </ng-template>
            <ng-template [ngIf]="form.value !== null && form.value.length >= searchOptions.length">
                All
            </ng-template>
            <ng-template [ngIf]="form.value !== null && form.value.length == 1">
                {{form.value[0].valueTitle}}
            </ng-template>
        </mat-select-trigger>
        <mat-checkbox [ngClass]="'select-all-spacer'"
            (click)="toggleAllSelection()"
            [indeterminate]="isIndeterminate()"
            [checked]="isChecked()"
            [disableRipple]="true">
            All </mat-checkbox>
        <mat-option *ngFor="let option of searchOptions" [value]="option">
            {{option.valueTitle}}
        </mat-option>
    </mat-select>

    <div *ngIf="errorState" class="criteria-error-text mat-small">
        {{errorMessage}}
    </div>
</div>

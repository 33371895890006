import { Pipe, PipeTransform } from '@angular/core';
import { Name } from 'src/app/shared/interfaces/name.interface';

@Pipe({
    name: 'name'
})
export class NamePipe implements PipeTransform {

    public transform(name: Name, surnameFirst?: boolean, includeMiddle?: boolean): string {
        let fullName = name.firstName;

        if (includeMiddle && name.middleName) {
            fullName += ' ' + name.middleName;
        }

        if (surnameFirst) {
            fullName = name.lastName + ', ' + fullName;
        } else {
            fullName = fullName + ' ' + name.lastName;
        }

        return fullName;
    }

}

<div class="info-tooltip multiple-appt-tooltip">
    <div>
        <p class="tooltip-header">Patient</p>
        <p class="bold-weight">{{ patientAppointment.patientName | name : true : true }}</p>
        <p class="bold-weight">
            {{ patientAppointment.dateOfBirth | date : 'shortDate' }} ({{ patientAppointment.dateOfBirth | age }})
            <span class="normal-weight">| Birth Sex:</span> {{ patientAppointment.gender }}
        </p>
        <p class="bold-weight"><span class="normal-weight">Patient ID:</span> {{ patientAppointment.patientId }}</p>
        <p class="bold-weight">{{ phoneNumber }}</p>
    </div>

    <div>
        <p class="tooltip-header top-margin">Appointment Details</p>

        <div class="tooltip-appt-details">
            <div *ngFor="let appointment of patientAppointment.appointments">
                <p>{{ appointment.appointmentDate | date : 'shortDate' }} {{ appointment.appointmentDate | date : 'shortTime' }}</p>
                <p>{{ appointment.provider | name }}</p>
                <p>{{ appointment.location }}</p>
                <p>{{ appointment.appointmentType }}</p>
            </div>
        </div>
    </div>
</div>

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';

export interface SelectorOption{
    value: Object | null;
    valueTitle: string;
}

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss', '../combo-selector/combo-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class DatePickerComponent{

    @Input()
    public criteriaTitle = '';

    @Input()
    public errorMessage = 'Default Error';

    @Input()
    public form: FormControl<Date | null> = new FormControl(new Date(), {nonNullable: true});

    @Input()
    public placeholderText = '';

    private _errorState = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private appointmentService: AppointmentSearchService) {
        this.appointmentService.checkSearchErrorsSubject.subscribe(
            () => {
                this.setErrorState();
            }
        );
    }

    public setErrorState() {
        this._errorState = this.form.errors != null;
        this.changeDetector.markForCheck();
    }

    public get errorState(): boolean {
        return this._errorState;
    }

}

<div [ngClass]="'criteria-section'">
    <single-selector
        [ngClass]="'appointment-search-box'"
        [form]="form.controls.subOrganizations"
        [criteriaTitle]="'SubOrganization'"
        [errorMessage]="'Suborganization is required.'"
        [searchOptions]="suborganizations"
        [placeholderText]="'Choose a suborganization'"
        />
    <date-picker
        [form]="form.controls.appointmentDate"
        [criteriaTitle]="'Appointment Date'"
        [errorMessage]="'Please enter a valid date.'"
        />
    <single-selector
        [ngClass]="'appointment-search-box'"
        [form]="form.controls.locations"
        [criteriaTitle]="'Location'"
        [errorMessage]="'Location is required.'"
        [searchOptions]="locations"
        [placeholderText]="'Choose a location'"
        />
    <combo-selector
        [ngClass]="'appointment-search-box'"
        [form]="form.controls.providers"
        [criteriaTitle]="'Provider'"
        [errorMessage]="'Provider is required.'"
        [searchOptions]="providers"
        [placeholderText]="'Choose a provider'"
        [plural]="'Providers'"
        />
    <combo-selector
        [ngClass]="'appointment-search-box'"
        [form]="form.controls.appointmentTypes"
        [criteriaTitle]="'Appointment Type'"
        [errorMessage]="'Appointment Type is required.'"
        [searchOptions]="appointmentTypes"
        [placeholderText]="'Choose an appt type'"
        [plural]="'Appt Type'"
        />
    <combo-selector
        [ngClass]="'appointment-search-box'"
        [form]="form.controls.appointmentStatuses"
        [criteriaTitle]="'Appointment Status'"
        [errorMessage]="'Appointment Status is required.'"
        [searchOptions]="appointmentStatuses"
        [placeholderText]="'Choose an appt status'"
        [plural]="'Appt Status'"
        />
</div>
<div [ngClass]="'appointment-search-section'">
    <submit-button 
        buttonTitle="Search"
        (submit)="submitSearch()">
    </submit-button>
</div>

<div class="patient-chat-header-margin">
    <div class="patient-chat-header-wrapper">
        <div class="patient-search-wrapper">
            
            <button mat-flat-button [matMenuTriggerFor]="menu" class='patient-search-filter'>
                <mat-icon class="filter-icon" matBadge="{{selections && selections.length != 0 ? selections.length : ''}}" matBadgeSize="small" aria-hidden="false">
                    filter_alt
                </mat-icon>
                Filters
                <mat-icon iconPositionEnd>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <mat-selection-list class="menu-list" mat-menu-items (click)="$event.stopPropagation()" [(ngModel)]="selections">
                    <mat-list-option *ngFor="let option of filterOptions" [value]="option">{{ option }}</mat-list-option>
                </mat-selection-list>
            </mat-menu>

            <mat-form-field class='patient-search-bar' appearance='outline'>
                <mat-icon matPrefix>search</mat-icon>
                
                <input matInput placeholder="Search by Patient Name" name='searchbar' autocomplete='off' #search 
                    [(ngModel)]="searchText"  [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of getAutocompleteList()" [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-autocomplete>
                
                <mat-icon [ngClass]="searchText !== '' ? '' : 'hide-visibility'" matSuffix class='patient-search-bar-clear'(click)="searchText = ''">
                    clear
                </mat-icon>
            </mat-form-field>
        </div>

        <div class="new-chat-button">
            <submit-button buttonTitle="New Chat" (click)="newChat()"></submit-button>
        </div>
    </div>
</div>
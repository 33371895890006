import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { filterOptions } from 'src/app/shared/enums/chat-filter.enum';
import { RoutePath } from 'src/app/shared/enums/route-path.enum';

@Component({
    selector: 'app-chat-page-menu',
    templateUrl: './chat-page-menu.component.html',
    styleUrls: ['./chat-page-menu.component.scss']
})

export class ChatPageMenuComponent {

    public autocompleteList: string[] = [];

    public filterOptions = Object.values(filterOptions);

    public patientList = [
        'Smith, John A - DOB: 06/18/1997 | Birth Sex: M | 9987652',
        'Smith, John - DOB: 01/02/1954 | Birth Sex: M | 9911245'
    ];

    public searchText?: string = '';

    public selections?: any;

    constructor(private router: Router) { }

    public getAutocompleteList(): string[] {
    // todo: should be like a pipe or something idk
        if (this.searchText) {
            return this.patientList.filter(patient => patient.toLowerCase().includes(this.searchText?.toLowerCase() ?? ''));
        }

        return [];
    }

    public newChat() {
        this.router.navigate([`/${RoutePath.Create}`]);
    }

    public search() {
    //
    }

}

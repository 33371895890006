// TODO - setup shared library to share this definition with CareNarrative

/**
 * Defines the messages passed between the host and the module.  Currently a subset of the messages defined in the fmh dashboard host, with the exception of the ServerToModuleMessage.  Keeping
 * compatability with dashboard will make it easier to migrate to the dashboard host in the future
 */


/**
 * A message sent from the server to the module via the websocket connection
 */
export interface ServerMessage<T> {
    messageType: string;
    moduleName: string;
    clientId?: number;
    data: T;
}

/**
 * A named authentication token
 */
export interface Token {
  name: string;
  value: string;
}

export const MPE_TOKEN_NAME = "MPE_TOKEN";

export interface OrganizationContext {
    ClientId: number; // NOTE: not available in dashboard, but needed inside MPE
}
  
export interface SessionContext extends OrganizationContext {
    Tokens: Token[];
}

/**
 * The message types that can be sent from the host to the module, along with the properties included on the message (not including the messageName property)
 */
export interface DashboardHostMessageTypeMap {
    /** sent when an authentication token changes */
    'POST_TOKEN': { tokens: Token[] };

    /** sent when module first starts, in response to the REQUEST_APPLICATION_INIT_DATA message from the module */
    'APPLICATION_INIT_DATA': SessionContext;

     /** sent when the organization in context  changes */
    'ORGANIZATION_CHANGED': OrganizationContext;

    /** sent when the websocket gets a message for a module */
    'SERVER_TO_MODULE': ServerMessage<any>;
}

/**
 * A type representing the DashboardMessage.  Has a messageName discriminator property that typescript can use to infer the type of the messageBody property
 * in the window event handler.
 */
export type DashboardHostMessage =
    { [K in keyof DashboardHostMessageTypeMap]: { messageName: K, messageBody: DashboardHostMessageTypeMap[K] } }[keyof DashboardHostMessageTypeMap];


/**
 * The message types that can be sent from the module to the host, along with the properties included on the message (not including the messageName property)
 */
export interface DashboardModuleMessageTypeMap {
    'APPLICATION_ALIVE': {};
    'APPLICATION_ERROR': {};
    'APPLICATION_READY': {};
    'REQUEST_APPLICATION_INIT_DATA': {};
    'MODULE_BREADCRUMB_CHANGE': { parts: string[] };
}

/**
 * A type representing the DashboardModuleMessage.  Has a messageName discriminator property.
 */
export type DashboardModuleMessage =
    { [K in keyof DashboardModuleMessageTypeMap]: DashboardModuleMessageTypeMap[K] & { messageName: K, moduleName: string, } }[keyof DashboardModuleMessageTypeMap]; 

import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from 'src/app/shared/enums/route-path.enum';
import { SessionInfoService } from 'src/app/services/session-info.service';
import { AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectorOption } from 'src/app/components/common/picker-components/combo-selector/combo-selector.component';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';
import { combineLatest } from 'rxjs';
import { AppointmentPage, AppointmentSearchCriteria, InitiatePatientChatRequest, PatientChatUiMetadata } from 'src/app/shared/interfaces/appointment-search.interface';
import { ChatAppointmentSearchData } from 'src/app/shared/interfaces/chat-appointment-search-data.interface';
import { AppointmentPhoneStatus } from 'src/app/shared/enums/appointment-phone-status.enum';
import { AppointmentRecord } from 'src/app/components/appointment-search-table/appointment-search-table.component';
import { ErrorAlerts } from 'src/app/shared/enums/error-alert.enum';

@Component({
    selector: 'app-create-chat',
    templateUrl: './create-chat.component.html',
    styleUrls: ['./create-chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateChatComponent implements OnInit{

    public appointmentCount = 0;

    public disableSubmitButton = false;

    public appointmentData: ChatAppointmentSearchData[] = [];

    public appointmentDictionary: Record<string, AppointmentRecord> = {};

    public appointmentStatuses: SelectorOption[] = [];

    public appointmentTypes: SelectorOption[] = [];

    public appointmentQueryForm = new FormGroup({
        subOrganizations: new FormControl<SelectorOption | null>(null, [Validators.required]),
        appointmentDate: new FormControl<Date | null>(new Date(), [Validators.required]),
        locations: new FormControl<SelectorOption | null>(null, [Validators.required]),
        providers: new FormControl<SelectorOption[]>([], [Validators.required]),
        appointmentTypes: new FormControl<SelectorOption[]>([], [Validators.required]),
        appointmentStatuses: new FormControl<SelectorOption[]>([], [Validators.required]),
    });

    public appointmentPaginationForm = new FormGroup({
        pageSize: new FormControl<number>(10, {nonNullable: true}),
        currentPage: new FormControl<number>(1, {nonNullable: true}),
    });

    public currentContext?: AppointmentSearchCriteria;

    public displayTable = false;

    public locations: SelectorOption[] = [];

    public providers: SelectorOption[] = [];

    public get selectedCount(): number {
        let selected = 0;

        Object.keys(this.appointmentDictionary).forEach( (key: string) => {
            const appointment = this.appointmentDictionary[key];
            if (appointment.phoneStatus === AppointmentPhoneStatus.Selected){
                selected++;
            }
        });

        return selected;
    }

    public singleOptions = false;

    public suborganizations: SelectorOption[] = [];

    public get tableResultCount() {
        return this.appointmentData.length;
    }

    public tableMessage = 'Search for appointments and select one or more to initiate a patient chat.';

    private static readonly noResultsMessage = 'No matching results.';

    private static readonly tableResultLimit = 500;

    constructor(
        private router: Router,
        private sessionInfoService: SessionInfoService,
        public dialog: MatDialog,
        private appointmentService: AppointmentSearchService,
        private changeDetector: ChangeDetectorRef) {
        this.appointmentQueryForm.controls.appointmentDate.setValue(new Date());
    }

    public ngOnInit(): void {
        const controls = this.appointmentQueryForm.controls;
        controls.subOrganizations.disable();
        controls.locations.disable();
        controls.providers.disable();
        controls.appointmentTypes.disable();
        controls.appointmentStatuses.disable();

        const requests = {
            organizations: this.appointmentService.getSuborganizations(),
            locations: this.appointmentService.getLocations(),
            providers: this.appointmentService.getProviders(),
            apptTypes: this.appointmentService.getAppointmentTypes(),
            apptStatuses: this.appointmentService.getAppointmentStatuses(),
        };
        combineLatest(requests).subscribe(({organizations, locations, providers, apptTypes, apptStatuses}) => {
            if (organizations === undefined || locations === undefined  || providers  === undefined  ||
                apptTypes === undefined  || apptStatuses  === undefined ){

                this.dialog.open(AlertPopoverComponent, {
                    data: ErrorAlerts.PageError,
                    width: AlertPopoverComponent.defaultWidth,
                });
            } else {
                this.suborganizations = organizations;
                this.locations = locations;
                this.providers = providers;
                this.appointmentTypes = apptTypes;
                this.appointmentStatuses = apptStatuses;

                this.setupDropMenus();
            }
        });
        this.sessionInfoService.updateBreadcrumb(['Campaigns', 'Patient Chat', 'New Chat']);
    }

    public goToPatientChat(): void {
        this.router.navigate([`/${RoutePath}`]);
    }

    public newPageRequest(): void {
        if (this.currentContext === undefined){
            this.dialog.open(AlertPopoverComponent, {
                data: ErrorAlerts.PageError,
                width: AlertPopoverComponent.defaultWidth,
            });
        } else {
            const pageControls = this.appointmentPaginationForm.controls;
            this.currentContext.page = pageControls.currentPage.value;
            this.currentContext.pageSize = pageControls.pageSize.value;

            this.requestTableResults(this.currentContext);
        }
    }

    public newQueryRequest(): void {
        if (!this.appointmentQueryIsValid()) {
            this.dialog.open(AlertPopoverComponent, {
                data: ErrorAlerts.CriteriaError,
                width: AlertPopoverComponent.defaultWidth,
            });
        } else {
            this.appointmentDictionary = {};

            const pageControl = this.appointmentPaginationForm.controls;
            pageControl.currentPage.setValue(1);

            const queryControls = this.appointmentQueryForm.controls;
            const pageControls = this.appointmentPaginationForm.controls;
            const searchRequest: AppointmentSearchCriteria =  {
                subOrganizationId: queryControls.subOrganizations.value?.value as number,
                appointmentDateTime: queryControls.appointmentDate.value,
                facilityIds: [queryControls.locations.value?.value as number],
                providerIds: queryControls.providers.value?.map(x => x.value as number),
                appointmentTypes: queryControls.appointmentTypes.value?.map(x => x.value as number),
                appointmentStatuses: queryControls.appointmentStatuses.value?.map(x => x.value as number),
                page: pageControls.currentPage.value,
                pageSize: pageControls.pageSize.value,
            };

            this.currentContext = searchRequest;

            this.requestTableResults(searchRequest);
        }
    }

    public requestTableResults(searchRequest: AppointmentSearchCriteria): void {

        this.appointmentService.getAppointmentPage(searchRequest)
            .subscribe({
                next: (response: AppointmentPage) => {
                    if (response.totalResults < 1) {
                        this.displayTable = false;
                        this.tableMessage = CreateChatComponent.noResultsMessage;
                    } else if (response.totalResults > CreateChatComponent.tableResultLimit){
                        this.dialog.open(AlertPopoverComponent, {
                            data: {
                                alertTitle: 'Refine Search Criteria',
                                alertText: `${response.totalResults} appointments matched your search criteria.\r\rPlease refine your search criteria to limit the appointments to a maximum of 500.`,
                            },
                            width: AlertPopoverComponent.defaultWidth,
                        });
                    } else {
                        this.appointmentData = response.responses.map((x): ChatAppointmentSearchData => ({
                            appointmentId: x.appointmentId,
                            selector: this.appointmentDictionary[x.appointmentId] ?
                                this.appointmentDictionary[x.appointmentId].phoneStatus : AppointmentPhoneStatus.Unselected,
                            appointmentDate: new Date(x.apptDate),
                            patientName: x.patientName,
                            dateOfBirth:  new Date(x.birthday).toLocaleString('en-IN', { day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-'), // using en-IN because it formats as DD MON YYYY ,replace spaces with - for DD-MON-YYYY
                            patientId: x.patientId,
                            patientExternalId: x.patientExternalId,
                            provider: x.providerName,
                            location: x.location,
                            facilityId: x.facilityId,
                            appointmentType: x.apptType,
                            apptStatus: x.apptStatus,
                            chatStatus: x.chatStatus,
                        }));

                        this.appointmentCount = response.totalResults;

                        this.displayTable = true;
                    }

                    this.changeDetector.detectChanges();
                },
                error: () => {
                    this.dialog.open(AlertPopoverComponent, {
                        data: ErrorAlerts.PageError,
                        width: AlertPopoverComponent.defaultWidth,
                    });
                }
            });
    }

    public sendMessages(options: InitiatePatientChatRequest): void {
        const chatPatients: PatientChatUiMetadata[] = [];

        if (this.selectedCount <= 0){
            this.dialog.open(AlertPopoverComponent, {
                data: ErrorAlerts.NoAppointmentsSelectedError,
                width: AlertPopoverComponent.defaultWidth,
            });
        } else if (options.MessageToSend === null || options.MessageToSend === '') {
            this.appointmentService.setMessageError();
        } else {
            Object.keys(this.appointmentDictionary).forEach( key => {
                const appointment = this.appointmentDictionary[key];
                if (appointment.phoneStatus === AppointmentPhoneStatus.Selected){
                    chatPatients.push(appointment.metaData);
                }
            });

            options.patientsToChatWith = chatPatients;

            this.disableSubmitButton = true;
            this.appointmentService.createPatientChat(options)
                .subscribe({
                    next: () => {
                        this.goToPatientChat();
                    },
                    error: () => {
                        this.dialog.open(AlertPopoverComponent, {
                            data: ErrorAlerts.SendError,
                            width: AlertPopoverComponent.defaultWidth,
                        });
                        this.disableSubmitButton = false;
                    }
                });
        }
    }

    public appointmentDictionaryUpdate() {
        this.changeDetector.detectChanges();
    }

    private appointmentQueryIsValid(): boolean {
        if (this.appointmentQueryForm === null) {
            return false;
        } else if (
            this.appointmentQueryForm.controls.appointmentStatuses.errors !== null ||
            this.appointmentQueryForm.controls.appointmentTypes.errors !== null ||
            this.appointmentQueryForm.controls.appointmentDate.errors !== null ||
            this.appointmentQueryForm.controls.locations.errors !== null ||
            this.appointmentQueryForm.controls.providers.errors !== null ||
            this.appointmentQueryForm.controls.subOrganizations.errors !== null) {
            return false;
        }
        return true;
    }

    private setDefaultValueMultiSelect(control: FormControl, options: SelectorOption[], defaultAll: boolean = false): void {
        const optionCount = options.length;
        control.setValue(optionCount === 1 ? [options[0]] : defaultAll ? options.map(x => x) : []);
    }

    private setDefaultValueSingleSelect(control: FormControl, options: SelectorOption[]): void {
        const optionCount = options.length;
        control.setValue(optionCount === 1 ? options[0] : null);
    }

    private setupDropMenus (): void {
        const controls = this.appointmentQueryForm.controls;
        this.setDefaultValueSingleSelect(controls.subOrganizations, this.suborganizations);
        controls.subOrganizations.enable();
        this.setDefaultValueMultiSelect(controls.locations, this.locations);
        controls.locations.enable();
        this.setDefaultValueMultiSelect(controls.providers, this.providers, true);
        controls.providers.enable();
        this.setDefaultValueMultiSelect(controls.appointmentTypes, this.appointmentTypes, true);
        controls.appointmentTypes.enable();
        this.setDefaultValueMultiSelect(controls.appointmentStatuses, this.appointmentStatuses, true);
        controls.appointmentStatuses.enable();
    }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ConversationInfo } from 'src/app/shared/interfaces/conversation-info.interface';
import { MessageDisplayType } from 'src/app/shared/enums/message-display-type.enum';
import { MessageStatus } from 'src/app/shared/enums/message-status.enum';

@Component({
    selector: 'app-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit, OnChanges {

    public readonly MessageStatus = MessageStatus;

    @Input()
    public conversation!: ConversationInfo;

    public messageDate?: Date;

    public readonly messageDisplayTypeEnums = MessageDisplayType;

    public ngOnChanges(): void {
        this.setFlags();
    }

    public ngOnInit(): void {
        this.setFlags();
    }

    public setFlags(): void {
        let closestToClosedDateMessageId = '';
        this.messageDate = undefined;
        const closedOnDateObject = new Date(this.conversation.closedOn);
        const shouldAddClosedLine = this.conversation.messages.findIndex(message => message.closedDisplay) === -1;

        this.conversation.messages.forEach(message => {

            // Cast to date object
            const msgDateObject = new Date(message.messageDateTime);

            // Date change
            if (this.messageDate === undefined || msgDateObject.toDateString() !== this.messageDate?.toDateString()) {
                this.messageDate = msgDateObject;
                message.isDateChange = true;
            } else {
                message.isDateChange = false;
            }

            // Check closed status and if message is before closedOn
            if (!this.conversation.isOpen && shouldAddClosedLine && msgDateObject < closedOnDateObject) {
                closestToClosedDateMessageId = message.id;
            }

            // Sender Name
            if (message.isAutoReply) {
                message.isUser = true;
                message.senderName = 'Auto Reply';

            } else if (message.sendingUser === undefined || message.sendingUser === null) {
                message.isUser = false;

            } else {
                message.isUser = true;
                let fullName = '';

                if (message.sendingUser.firstName) {
                    fullName = message.sendingUser.firstName;
                }
                if (message.sendingUser.middleName) {
                    fullName += ` ${message.sendingUser.middleName}`;
                }
                if (message.sendingUser.lastName && fullName) {
                    fullName = `${message.sendingUser.lastName}, ${fullName}`;
                } else {
                    if (message.sendingUser.lastName) {
                        fullName = message.sendingUser.lastName;
                    }
                }

                message.senderName = fullName;
            }

            // Delivery message
            // default to success. We won't show sucess on the message until deliveryMsg is set
            message.displayType = MessageDisplayType.Success;
            if (message.status === MessageStatus.OptedOut) {
                message.deliveryMsg = 'Opted Out of SMS';
                message.displayType = MessageDisplayType.Failure;
                message.iconName = 'OptOut';
            } else if (message.status === MessageStatus.InvalidNumber) {
                message.deliveryMsg = 'Missing or Invalid Number';
                message.displayType = MessageDisplayType.Failure;
                message.iconName = 'Phone-Error';
            } else if (message.status === MessageStatus.Error) {
                message.deliveryMsg = 'Failed to Deliver';
                message.displayType = MessageDisplayType.Failure;
                message.iconName = 'Send-Error';
            } else if (message.status === MessageStatus.DeliveredToHandset) {
                message.deliveryMsg = 'Delivered';
            }
        });

        if (closestToClosedDateMessageId !== '') {
            const messageIndex = this.conversation.messages.findIndex(message => message.id === closestToClosedDateMessageId);
            if (messageIndex > -1) {
                if (this.conversation.closedBy === null) {
                    this.conversation.messages[messageIndex].closedDisplay = MessageDisplayType.ClosedAutomatically;
                } else {
                    this.conversation.messages[messageIndex].closedDisplay = MessageDisplayType.ClosedManually;
                }
            }
        }
    }
}

<div class="chat-window-header">
    <div class="chat-header-patients">
        <div class="header-patient" *ngFor="let patientAppt of (chatInfo.patients | sortAppointments ) | slice : 0 : patientsShown">
            <div class="header-appt-details">
                <p class="name-wrapper patient-name">
                    {{ patientAppt.patientName.lastName }}, <span class="first-name">{{ patientAppt.patientName.firstName }}</span>
                </p>
                <span class="header-divider"> | </span>
                <p>{{ patientAppt.dateOfBirth | date: "shortDate" }} ({{ patientAppt.dateOfBirth | age }})</p>
                <span class="header-divider"> | </span>

                <p>
                    {{ patientAppt.appointments[0].appointmentDate | date: "shortDate" }}
                    {{ patientAppt.appointments[0].appointmentDate | date: "shortTime" }}
                    {{ patientAppt.appointments[0].appointmentTimeZone }}
                </p>
                <span class="header-divider"> | </span>
                <p class="name-wrapper">{{ patientAppt.appointments[0].provider | name }}</p>
            </div>

            <mat-icon cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="toggleInfo($event, patientAppt)">info</mat-icon>
        </div>

        <ng-container *ngIf="chatInfo.patients.length > defaultPatientsShown">
            <a class="view-more" (click)="viewMore()" *ngIf="patientsShown === defaultPatientsShown">
                View {{ chatInfo.patients.length - defaultPatientsShown }} more
            </a>

            <a class="view-more" (click)="viewLess()" *ngIf="patientsShown !== defaultPatientsShown">
                View less
            </a>
        </ng-container>
    </div>

    <ng-container *ngIf="chatInfo.isOpen; else chatClosed">
        <submit-button class="close-chat-btn" buttonTitle="Close Chat" (click)="closeChat()"></submit-button>
    </ng-container>

    <ng-template #chatClosed>
        <div class="chat-closed">
            <img class="locked-icon"src="/assets/icon/Locked.svg">
            <p>Chat is Closed</p>
        </div>
    </ng-template>
</div>

<ng-template cdkConnectedOverlay cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="triggerOrigin"
        [cdkConnectedOverlayOpen]="infoIsOpen" (backdropClick)="clickOutside()">
    <app-chat-info-panel [patientAppointment]="infoToShow" [phoneNumber]="chatInfo.phoneNumber"></app-chat-info-panel>
</ng-template>

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'submit-button',
    templateUrl: './submit-button.component.html',
    styleUrls: ['./submit-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent {
    @Input()
    public buttonTitle: String = 'No Title Provided';

    @Input()
    public disabled = false;

    @Output()
    public submit: EventEmitter<any> = new EventEmitter();

    constructor() { }

    public clicked(): void {
        if (!this.disabled) {
            this.submit.emit(null);
        }
    }
}

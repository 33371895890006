import { Component, Input } from '@angular/core';
import { PatientAppointment } from 'src/app/shared/interfaces/patient-appointment.interface';

@Component({
    selector: 'app-chat-info-panel',
    templateUrl: './chat-info-panel.component.html',
    styleUrls: ['./chat-info-panel.component.scss']
})
export class ChatInfoPanelComponent {
    @Input()
    public patientAppointment!: PatientAppointment;

    @Input()
    public phoneNumber = '';
}

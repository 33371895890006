<div class="message-options-section message-box-sizer">
    <text-input 
        [header]="'Message'"
        [placeholderText] = "'Type a new message'"
        [counterLocation]="'Above'"
        [textlines] = 4
        [(value)]="message"
        [characterLimit] = 2000>
    </text-input>
</div>

<div *ngIf="errorState" class="criteria-error-text">
    {{errorMessage}}
</div>

<div class="message-options-section">
    A chat will be created for each recipient with the message above, unless they share the same mobile number. Recipients that share a number will be grouped in a single chat. If the recipient(s) already has an open chat, the message above will appear in that chat. Text messages are not secure. Chats are intended for administrative purposes, should never contain medical information, and are deleted 14 days after closure.
</div>
<!-- Stretch Goal Component for later release
<div class="option-align-center message-options-section">
    <mat-checkbox></mat-checkbox>
    <p>Close chat(s) after message is sent</p>
    <img src="assets\icon\Info-Clear.svg">
</div>
-->
<div class="option-align-right message-options-section ">
    <submit-button 
        buttonTitle="Send Message"
        [disabled] = "disableSubmitButton"
        (submit)="sendMessage()">
    </submit-button>
</div>
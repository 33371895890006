<div class="patient-chat-page">
    <app-chat-page-menu></app-chat-page-menu>

    <div class="patient-chat-wrapper">
        <app-conversation-list (selectChat)="chatSelected($event)"></app-conversation-list>
        <app-conversation-window [messagesLoaded]="messagesLoaded.asObservable()" [chat]="selectedChat" [loading]="loadingConversation"></app-conversation-window>
    </div>

    <p class="disclaimer">Text messages are not secure. Chats are intended for administrative purposes, should never contain medical information, and are deleted 14 days after closure.</p>
</div>

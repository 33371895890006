<div ngClass="conversation-card {{ isSelected ? 'card-selected' : '' }}">

    <ng-container *ngIf="cardInfo">
        <!-- Patient(s), appointment, text preview -->
        <div class="conversation-wrapper">
            <div class="dot-wrapper">
                <span ngClass="{{ getDotClass() }}">•</span>
            </div>

            <div class="conversation-content">
                <mat-card-title class="truncate-two-lines">
                    <span *ngFor="let patientappointment of cardInfo.patients; index as i">
                        {{ patientappointment.patientName | name : true }}<span *ngIf="i != cardInfo.patients.length - 1">;</span>
                    </span>
                </mat-card-title>

                <!-- Only the first appointment is shown, and only if there's a single patient appt in context -->
                <mat-card-subtitle *ngIf="cardInfo.patients.length == 1 && cardInfo.patients[0].appointments.length >= 1">
                    {{ cardInfo.patients[0].appointments[0].appointmentDate | date : 'MM/dd/yyyy hh:mm' }} {{ cardInfo.patients[0].appointments[0].appointmentTimeZone }}
                    <span class="divider"> | </span>
                    {{ cardInfo.patients[0].appointments[0].provider | name }}
                </mat-card-subtitle>

                <mat-card-content class="truncate-two-lines">
                    {{ cardInfo.previewMessage }}
                </mat-card-content>
            </div>
        </div>

        <!-- Timestamp & icons -->
        <div class="conversation-info">
            <p class="message-time">{{ cardInfo.lastUpdated | conversationTimestamp : !cardInfo.isOpen }}</p>

            <tooltip *ngIf="otherPatients != 0">
                <div subject ngClass="more-patients {{ isSelected ? 'card-not-selected' : 'card-selected' }}">+{{ otherPatients }}</div>

                <span tooltip class="tooltip-content tooltip-header">
                    <span>{{ otherPatients }} other patient(s) in this chat</span>
                </span>
            </tooltip>

            <div class="conversation-icons" *ngIf="cardInfo.errorState || (cardInfo.usersInChat && cardInfo.usersInChat.length > 1)">
                <ng-container [ngSwitch]="cardInfo.errorState">

                    <tooltip *ngSwitchCase="ErrorStates.FailedToSend">
                        <mat-icon subject class="message-icon" svgIcon="Send-Error"/>

                        <span tooltip class="tooltip-content">
                            <span>Failed to deliver</span>
                        </span>
                    </tooltip>

                    <tooltip *ngSwitchCase="ErrorStates.OptedOut">
                        <mat-icon subject class="message-icon" svgIcon="OptOut"/>

                        <span tooltip class="tooltip-content">
                            <span>Opted out of SMS</span>
                            <span>Text RESUME to {{ cardInfo.resumeCode }}</span>
                        </span>
                    </tooltip>

                    <tooltip *ngSwitchCase="ErrorStates.InvalidNumber">
                        <mat-icon subject class="message-icon" svgIcon="Phone-Error"/>

                        <span tooltip class="tooltip-content">
                            <span>Missing/invalid number</span>
                        </span>
                    </tooltip>

                </ng-container>

                <tooltip *ngIf="cardInfo.usersInChat && cardInfo.usersInChat.length > 1">
                    <img subject class="message-icon" src="/assets/icon/Msg-Users.svg">

                    <span tooltip class="tooltip-content">
                        <span class="tooltip-header">Users in this chat:</span>
                        <span *ngFor="let user of cardInfo.usersInChat">{{ user.lastName }}, {{ user.firstName }}</span>
                    </span>
                </tooltip>

            </div>
        </div>
    </ng-container>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { millisecondsInADay, millisecondsInAWeek } from '../enums/time-units.enum';
import { clearDate } from '../functions/clear-date.function';

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {

    private ageInDays(ageInMs: number): string {
        const days = Math.floor(ageInMs / millisecondsInADay);

        return days + ' days';
    }

    private ageInMonths(dob: Date, today: Date): string {
        let months = (today.getFullYear() - dob.getFullYear()) * 12;
        months -= dob.getMonth();
        months += today.getMonth();

        return months + ' months';
    }

    private ageInWeeks(ageInMs: number): string {
        const weeks = Math.floor(ageInMs / millisecondsInAWeek);

        return weeks + ' weeks';
    }

    private ageInYears(dob: Date, today: Date): string {
    // Subtract the years to get the age
        let age = today.getFullYear() - dob.getFullYear();

        // Subtract the months to see if user's birthday has passed this year
        const month = today.getMonth() - dob.getMonth();

        // If birth day has not passed, subtract age by one
        if (month < 0 || (month === 0 && today.getDate() < dob.getDate())) {
            age--;
        }

        // Save age
        return age + ' years';
    }

    /**
   *  days    0 days - 27 days old
   *  weeks   28 days - 25 weeks
   *  months  26 weeks - 23 months old
   *  years   24+ months old
   * @param dob
   * @returns
   */
    public transform(dob: Date | string): string {
        if (typeof dob === 'string') {
            dob = new Date(dob);
        }
        dob = clearDate(dob);
        const today = clearDate(new Date());

        const ageInMs = today.getTime() - dob.getTime();

        const twentyEightDaysAgo = new Date(new Date().setDate( today.getDate() - 28));
        const twentySixWeeksAgo = new Date(new Date().setDate( today.getDate() - (7 * 26)));
        const twoYearsAgo = new Date(new Date().setFullYear(today.getFullYear() - 2));

        if (twentyEightDaysAgo < dob) {
            return this.ageInDays(ageInMs);
        } else if (twentySixWeeksAgo < dob) {
            return this.ageInWeeks(ageInMs);
        } else if (twoYearsAgo < dob) {
            return this.ageInMonths(dob, today);
        } else {
            return this.ageInYears(dob, today);
        }
    }
}

import { Component, ChangeDetectionStrategy,Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectorOption } from 'src/app/components/common/picker-components/combo-selector/combo-selector.component';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';

@Component({
    selector: 'appointment-search-bar',
    templateUrl: './appointment-search-bar.component.html',
    styleUrls: ['./appointment-search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSearchBarComponent {
    @Input()
    public appointmentStatuses: SelectorOption[] = [];

    @Input()
    public appointmentTypes: SelectorOption[] = [];

    @Input()
    public form = new FormGroup(
            {
                subOrganizations: new FormControl<SelectorOption | null>(null, [Validators.required]),
                appointmentDate: new FormControl<Date | null>(new Date(), [Validators.required]),
                locations: new FormControl<SelectorOption | null>(null, [Validators.required]),
                providers: new FormControl<SelectorOption[]>([], [Validators.required]),
                appointmentTypes: new FormControl<SelectorOption[]>([], [Validators.required]),
                appointmentStatuses: new FormControl<SelectorOption[]>([], [Validators.required]),
            }
        );

    @Input()
    public locations: SelectorOption[] = [];

    @Input()
    public providers: SelectorOption[] = [];

    @Input()
    public suborganizations: SelectorOption[] = [];

    @Output()
    public submit: EventEmitter<any> = new EventEmitter();

    constructor(private appointmentService: AppointmentSearchService) {
    }

    public submitSearch(): void {
        let errors = false;
        if (this.form.get('subOrganizations')?.errors != null) {
            errors = true;
        }
        if (this.form.get('appointmentDate')?.errors != null) {
            errors = true;
        }
        if (this.form.get('locations')?.errors != null) {
            errors = true;
        }
        if (this.form.get('providers')?.errors != null) {
            errors = true;
        }
        if (this.form.get('appointmentTypes')?.errors != null) {
            errors = true;
        }
        if (this.form.get('appointmentStatuses')?.errors != null) {
            errors = true;
        }

        this.appointmentService.checkForSearchErrors();

        if (!errors) {
            this.submit.emit();
        }
    }

}

<div class="page-border">
    <return-button class="return-button" (click)="goToPatientChat()" >Back to Chat View</return-button>
    <h1 class="page-header create-chat-section"> Search By Appointment </h1>
    <appointment-search-bar
        [ngClass]="'create-chat-section'"
        (submit)="newQueryRequest()"
        [form]="appointmentQueryForm"
        [appointmentStatuses]="appointmentStatuses"
        [appointmentTypes]="appointmentTypes"
        [locations]="locations"
        [providers]="providers"
        [suborganizations]="suborganizations"
    />
    <ng-container [ngSwitch]="displayTable">
        <div [ngClass]="'create-chat-table-message'" *ngSwitchCase="false">
            {{tableMessage}}
        </div>
        <div *ngSwitchCase="true">
            <appointment-search-table
                [appointmentDictionary]="appointmentDictionary"
                [currentContext]="currentContext"
                [dataSource]="appointmentData"
                (appointmentDictionaryUpdated)="appointmentDictionaryUpdate()"
                [totalResults]="appointmentCount"/>
            <app-pagination
                [displayedItems]="tableResultCount"
                [form]="appointmentPaginationForm"
                [totalResults]="appointmentCount"
                (requestPage)="newPageRequest()"/>
            <create-message-options
                class="create-chat-section create-chat-message"
                [disableSubmitButton]="disableSubmitButton"
                (submitMessage)="sendMessages($event)"
                />
        </div>
    </ng-container>

</div>

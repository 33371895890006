import { AppointmentPhoneStatus } from 'src/app/shared/enums/appointment-phone-status.enum';
import { ChatAppointmentSearchData } from 'src/app/shared/interfaces/chat-appointment-search-data.interface';
import { ChatStatus } from 'src/app/shared/enums/chat-status.enum';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';
import { AppointmentSearchCriteria, EligibilityModel, PatientChatUiMetadata } from 'src/app/shared/interfaces/appointment-search.interface';
import { AlertConfiguration, AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorAlerts } from 'src/app/shared/enums/error-alert.enum';
import { AwaitPopoverComponent } from 'src/app/components/common/popovers/await-popover/await-popover.component';
import { Subject } from 'rxjs';

export interface AppointmentRecord {
    phoneStatus: AppointmentPhoneStatus;
    metaData: PatientChatUiMetadata;
}

@Component({
    selector: 'appointment-search-table',
    templateUrl: './appointment-search-table.component.html',
    styleUrls: ['./appointment-search-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class AppointmentSearchTableComponent {

    public get allSelector(): AppointmentPhoneStatus {
        if (this.selectedCount === 0 ) {
            return AppointmentPhoneStatus.Unselected;
        } else if (this.selectedCount < this.totalResults ) {
            return AppointmentPhoneStatus.Partial;
        } else {
            return AppointmentPhoneStatus.Selected;
        }
    }

    @Input()
    public appointmentDictionary: Record<string, AppointmentRecord> = {};

    @Output()
    public appointmentDictionaryUpdated: EventEmitter<any> = new EventEmitter<any>();

    public readonly chatStatusEnums = ChatStatus;

    @Input()
    public currentContext?: AppointmentSearchCriteria;

    @Input()
    public dataSource: ChatAppointmentSearchData[] = [];

    public displayedColumns: string[] = [
        'Selector',
        'AppointmentDate',
        'PatientName',
        'DateOfBirth',
        'PatientId',
        'ProviderName',
        'LocationName',
        'AppointmentType',
        'AppointmentStatus',
        'ChatStatus',
    ];

    @Output()
    public refresh: EventEmitter<any> = new EventEmitter();

    public get selectedCount() {
        const keys = Object.keys(this.appointmentDictionary);
        let count = 0;
        keys.forEach( (key: string) => {
            if (this.appointmentDictionary[key].phoneStatus === AppointmentPhoneStatus.Selected) {
                count++;
            }
        });
        return count;
    }

    public readonly selectorStatusEnums = AppointmentPhoneStatus;

    @Input()
    public totalResults = 0;

    public get validatedCount() {
        const keys = Object.keys(this.appointmentDictionary);
        let count = 0;
        keys.forEach( (key: string) => {
            if (this.appointmentDictionary[key].phoneStatus === AppointmentPhoneStatus.Selected ||
                this.appointmentDictionary[key].phoneStatus === AppointmentPhoneStatus.Invalid) {
                count++;
            }
        });
        return count;
    }

    private static readonly validationErrorAlert: AlertConfiguration = {
        alertTitle: 'Error Requesting Data',
        alertText: 'We\'re having trouble requesting data right now, please try again later. If this problem persists, please contact support.',
    };

    constructor(
        private changeDetector: ChangeDetectorRef,
        private appointmentService: AppointmentSearchService,
        public dialog: MatDialog,
    ) { }

    public deselectAll(): void {
        for (const key in this.appointmentDictionary){
            if (Object.prototype.hasOwnProperty.call(this.appointmentDictionary, key)) {
                const record = this.appointmentDictionary[key];
                record.phoneStatus = AppointmentPhoneStatus.Unselected;
            }
        }

        this.dataSource.forEach(x => {
            if (x.selector === AppointmentPhoneStatus.Selected) {
                this.deselectAppointment(x.appointmentId);
            }
        });

        this.appointmentDictionaryUpdated.emit();
        this.changeDetector.detectChanges();
    }

    public deselectAppointment(apptId: number): void {
        const appointment = this.dataSource.find(x => x.appointmentId === apptId);
        if (appointment == null){
            return;
        }
        appointment.selector = AppointmentPhoneStatus.Unselected;
        this.appointmentDictionary[appointment.appointmentId].phoneStatus = AppointmentPhoneStatus.Unselected;
        this.appointmentDictionaryUpdated.emit();
        return;
    }

    // TODO: Temporarily disabled placeholder function Remove linter skips when this function is impemented
    // eslint-disable-next-line no-unused-vars, no-empty-function
    public previewChat(apptId: string): void {
    }

    public refreshTable(): void {
        this.refresh.emit(null);
    }

    public selectAll(): void {
        if (!this.currentContext) {
            this.dialog.open(AlertPopoverComponent, {
                data: ErrorAlerts.PageError,
                width: AlertPopoverComponent.defaultWidth,
            });
            return;
        }

        const awaitMessage = ErrorAlerts.PleaseWaitMessage;
        awaitMessage.closeTrigger = new Subject<boolean>();

        this.dialog.open(AwaitPopoverComponent, {
            data: awaitMessage,
            width: AlertPopoverComponent.defaultWidth,
        });

        const request = Object.assign({}, this.currentContext);

        request.page = 1;
        request.pageSize = this.totalResults;

        this.appointmentService.validateAllAppointmentPhones(request).subscribe({
            next: (result) => {
                result.responses.forEach(entry => {
                    const isValid = entry.isValid ? AppointmentPhoneStatus.Selected : AppointmentPhoneStatus.Invalid;
                    this.appointmentDictionary[entry.appointmentId] = {
                        phoneStatus: isValid,
                        metaData: {
                            patientId: entry.patientId,
                            appointmentId: entry.appointmentId,
                            facilityId: entry.facilityId,
                            normalizedPhoneNumber: entry.normalizedPhoneNumber,
                        },
                    };

                    const appointment = this.dataSource.find(x => x.appointmentId === entry.appointmentId);
                    if (appointment) {
                        appointment.selector = isValid;
                    }
                });

                awaitMessage.closeTrigger?.next(true);
                this.appointmentDictionaryUpdated.emit();
                this.changeDetector.detectChanges();
            },
            error: () => {
                awaitMessage.closeTrigger?.next(true);

                this.dialog.open(AlertPopoverComponent, {
                    data: ErrorAlerts.PageError,
                    width: AlertPopoverComponent.defaultWidth,
                });
            }
        });
    }

    public async selectAppointment(apptId: number): Promise<void> {
        const appointment = this.dataSource.find(x => x.appointmentId === apptId);
        if (appointment == null){
            return;
        }

        appointment.selector = AppointmentPhoneStatus.Searching;
        this.appointmentService.validateAppointmentPhone(appointment.appointmentId).subscribe({
            next: (response: EligibilityModel) => {
                const isValid = response !== undefined && response.eligible
                    ? AppointmentPhoneStatus.Selected : AppointmentPhoneStatus.Invalid;

                appointment.selector = isValid;
                this.appointmentDictionary[appointment.appointmentId] = {
                    phoneStatus: isValid,
                    metaData: {
                        patientId: appointment.patientId,
                        appointmentId: appointment.appointmentId,
                        facilityId: appointment.facilityId,
                        normalizedPhoneNumber: response.normalizedPhoneNumber,
                    },
                };

                this.appointmentDictionaryUpdated.emit();
                this.changeDetector.detectChanges();
            },
            error: () => {
                const appointmentMetaData = {
                    patientId: appointment.patientId,
                    appointmentId: appointment.appointmentId,
                    facilityId: appointment.facilityId,
                    normalizedPhoneNumber: null,
                };

                this.appointmentDictionary[appointment.appointmentId] = {
                    phoneStatus: AppointmentPhoneStatus.Invalid,
                    metaData: appointmentMetaData,
                };

                appointment.selector = AppointmentPhoneStatus.Invalid;

                this.dialog.open(AlertPopoverComponent, {
                    data: AppointmentSearchTableComponent.validationErrorAlert,
                    width: AlertPopoverComponent.defaultWidth,
                });

                this.appointmentDictionaryUpdated.emit();
                this.changeDetector.detectChanges();
            }
        });
    }

    public toggleSelection() {

        if ( this.validatedCount === 0 && this.validatedCount < this.totalResults) {
            this.selectAll();
        } else {
            this.deselectAll();
        }

    }
}

<nav>
    <ul [ngClass]="'card-list-section'">
        <li [ngClass]="'page-cards'">
            <button
                [disabled]="currentPage === 1"
                (click)="onGoTo(1)">
                <img src="assets/icon/Move-To-Front.svg">
            </button>
        </li>
        <li [ngClass]="'page-cards'">
            <button
                [disabled]="currentPage === 1"
                (click)="onPrevious()">
                <img src="assets/icon/Move-Forward.svg">
            </button>
        </li>
        <li [ngClass]="'page-cards'" *ngFor="let page of pages;">
            <a  *ngIf="getTensDigit(page - 1) === chapter; else out_of_range_page" [ngClass]="page === currentPage ? 'Current Page, Page ' + page : 'Go to Page ' + page"
                [class.current]="page === currentPage"
                tabindex="0"
                (click)="onGoTo(page)"
                (keyup.enter)="onGoTo(page)">
                {{ page }} 
            </a>
            <ng-template #out_of_range_page>
                <a  [ngClass]="page === currentPage ? 'Current Page, Page ' + page : 'Go to Page ' + page"
                [class.current]="page === currentPage"
                tabindex="0"
                (click)="onGoTo(page)"
                (keyup.enter)="onGoTo(page)">
                ...
            </a>
            </ng-template>
        </li>
        <li [ngClass]="'page-cards'">
            <button
                [disabled]="currentPage === totalPages"
                (click)="onNext()">
                <img src="assets/icon/Move-Back.svg">
            </button>
        </li>
        <li [ngClass]="'page-cards'">
            <button
                [disabled]="currentPage === totalPages"
                (click)="onGoTo(totalPages)">
                <img src="assets/icon/Move-To-Back.svg">
            </button>
        </li>
    </ul>
    <ul [ngClass]="'page-size-section'">        
        <mat-select
            [formControl]="form.controls.pageSize"
            [ngClass]="'page-size-selector'" 
            [value]="pageSizeOptions[0]"
            (selectionChange)="requestNewPageSize($event)">
            <mat-option *ngFor="let size of pageSizeOptions" [value]="size">
            {{size}}
            </mat-option>
        </mat-select>
        <p> items per page </p>
    </ul>
    <ul [ngClass]="'chapter-description-section'">
        {{pageDescription}}
    </ul>
</nav>
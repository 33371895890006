import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'conversationTimestamp'
})
export class ConversationTimestampPipe implements PipeTransform {

    public transform(lastMessageTime: string | number | Date, messageClosed: boolean): string {
        if (messageClosed) {
            return 'Closed';
        }

        const datePipe = new DatePipe('en-US');

        const today = new Date();
        const yesterday = new Date(today.setDate(today.getDate() - 1));

        // If the lsat message is within the last 24 hours, display the time, otherwise display the date
        const format = new Date(lastMessageTime) > yesterday ? 'shortTime' : 'shortDate';

        return datePipe.transform(lastMessageTime, format) ?? '';
    }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

    public get chapter(){
        return this.getTensDigit(this.pages[0]);
    }

    public get pageDescription() {
        return `${(this.currentPage - 1) * this.pageSize + 1}-${(this.currentPage - 1) * this.pageSize + this.displayedItems} of ${this.totalResults} items`;
    }

    public get currentPage(): number {
        return this.form.controls.currentPage.value;
    }

    @Input()
    public displayedItems = 0;

    @Input()
    public pageSizeOptions: number[] = [5, 10, 20];

    public get pageSize (): number {
        return this.form.controls.pageSize.value;
    }

    @Input()
    public form = new FormGroup({
            pageSize: new FormControl<number>(10, {nonNullable: true}),
            currentPage: new FormControl<number>(1, {nonNullable: true}),
        });

    public get pages() {
        if (this.totalPages <= 10) {
            return [...Array(this.totalPages).keys()].map(x => ++x);
        }

        // switch context is the 10 pages of the second digit's place
        if (this.currentPage > 10) {
            const currentSet = this.getTensDigit(this.currentPage - 1) * 10;
            const maxSet = this.getTensDigit(this.totalPages) * 10;

            // End of list cards
            if (this.currentPage >= maxSet + 1){
                const list: number[] = [];
                for (let i = maxSet; i <= this.totalPages; i++) {
                    list.push(i);
                }
                return list;
            } else {
                return [
                    currentSet,
                    currentSet + 1,
                    currentSet + 2,
                    currentSet + 3,
                    currentSet + 4,
                    currentSet + 5,
                    currentSet + 6,
                    currentSet + 7,
                    currentSet + 8,
                    currentSet + 9,
                    currentSet + 10,
                    currentSet + 11,
                ];
            }
        }

        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }

    @Output()
    public requestPage: EventEmitter<any> = new EventEmitter<any>();

    public get totalPages() {
        return Math.ceil(this.totalResults / this.pageSize);
    }

    @Input()
    public totalResults = 0;

    constructor() {
    }

    public getTensDigit(num: number): number {
        return Math.trunc((num)/10);
    }

    public onGoTo(page: number): void {
        this.form.controls.currentPage.setValue(page);
        this.requestNewPage();
    }

    public onNext(): void {
        this.form.controls.currentPage.setValue(this.currentPage + 1);
        this.requestNewPage();
    }

    public onPrevious(): void {
        this.form.controls.currentPage.setValue(this.currentPage - 1);
        this.requestNewPage();
    }

    public requestNewPage() {
        this.requestPage.emit();
    }

    public requestNewPageSize(event: MatSelectChange) {
        this.form.controls.currentPage.setValue(1);
        this.form.controls.pageSize.setValue(event.value);
        this.requestNewPage();
    }
}

<div [ngClass]="'header-area'">
    <span class="page-sub-header"> {{header}} </span>
    <span *ngIf="'Above'">
        <span *ngIf="characterLimit !== null; else elseBlock">{{characterCount}} / {{characterLimit}}</span>
        <ng-template #elseBlock> <span>{{characterCount}}</span> </ng-template>
    </span>
</div>
<textarea 
    [ngClass]="{'in-box-counter-textarea': counterLocation === 'Inside'}"
    matInput 
    placeholder={{placeholderText}}
    rows={{textlines}}
    wrap="hard"
    [(ngModel)]="value"
    (change)="valueUpdate()"
    maxlength={{characterLimit}}
    >{{value}}</textarea><!--No spaces here since it sets the default value-->

<span *ngIf="counterLocation === 'Inside'" [ngClass]="'in-box-character-counter'">
    <span *ngIf="characterLimit !== null; else elseBlock">{{characterCount}} / {{characterLimit}}</span>
    <ng-template #elseBlock> <span>{{characterCount}}</span> </ng-template>
</span>
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectorOption } from 'src/app/components/common/picker-components/combo-selector/combo-selector.component';
import { environment } from 'src/environments/environment';
import { ApiPaths } from 'src/app/shared/enums/api-paths.enum';
import { AppointmentPage, AppointmentSearchCriteria, AppointmentStatusResponse, AppointmentTypeResponse, ChatAppPhoneLookupPage, EligibilityModel, InitiatePatientChatRequest, LocationResponse, ProviderResponse, SubOrganizationResponse } from 'src/app/shared/interfaces/appointment-search.interface';
import { CareNarrativeServiceBase } from './care-narrative-service-base.service';

@Injectable({
    providedIn: 'root'
})
export class AppointmentSearchService extends CareNarrativeServiceBase {

    public checkSearchErrorsSubject: Subject<void> = new Subject<void>();

    public setMessageErrorSubject: Subject<void> = new Subject<void>();

    public checkForSearchErrors() {
        this.checkSearchErrorsSubject.next();
    }

    public setMessageError() {
        this.setMessageErrorSubject.next();
    }

    public getSuborganizations (): Observable<SelectorOption[] | undefined> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.Suborganizations}`;

        return new Observable<SelectorOption[]>((observer) => {
            this.http.get<SubOrganizationResponse[]>(url, this.options)
                .subscribe({
                    next: (response: SubOrganizationResponse[]) => {
                        const selectors = response.map<SelectorOption>(x => ({
                            value: x.subOrganizationId,
                            valueTitle: x.subOrganizationTitle
                        }));
                        observer.next(selectors);
                    },
                    error: () => {
                        observer.next(undefined);
                    }
                });
        });
    }

    public getLocations (): Observable<SelectorOption[] | undefined> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.Locations}`;

        return new Observable<SelectorOption[]>((observer) => {
            this.http.get<LocationResponse[]>(url, this.options)
                .subscribe({
                    next: (response: LocationResponse[]) => {
                        const selectors = response.map<SelectorOption>(x => ({value: x.locationId, valueTitle: x.locationTitle}));
                        observer.next(selectors);
                    },
                    error: () => {
                        observer.next(undefined);
                    }
                });
        });
    }

    public getProviders (): Observable<SelectorOption[] | undefined> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.Providers}`;

        return new Observable<SelectorOption[]>((observer) => {
            this.http.get<ProviderResponse[]>(url, this.options)
                .subscribe({
                    next: (response: ProviderResponse[]) => {
                        const selectors = response.map<SelectorOption>(x => ({value: x.providerId, valueTitle: x.providerName}));
                        observer.next(selectors);
                    },
                    error: () => {
                        observer.next(undefined);
                    }
                });
        });
    }

    public getAppointmentTypes (): Observable<SelectorOption[] | undefined> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.AppointmentTypes}`;

        return new Observable<SelectorOption[]>((observer) => {
            this.http.get<AppointmentTypeResponse[]>(url, this.options)
                .subscribe({
                    next: (response: AppointmentTypeResponse[]) => {
                        const selectors = response.map<SelectorOption>(x => ({value: x.typeId, valueTitle: x.typeName}));
                        observer.next(selectors);
                    },
                    error: () => {
                        observer.next(undefined);
                    }
                });
        });
    }

    public getAppointmentStatuses (): Observable<SelectorOption[] | undefined> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.AppointmentStatus}`;

        return new Observable<SelectorOption[]>((observer) => {
            this.http.get<AppointmentStatusResponse[]>(url, this.options)
                .subscribe({
                    next: (response: AppointmentStatusResponse[]) => {
                        const selectors = response.map<SelectorOption>(x => ({value: x.statusId, valueTitle: x.statusName}));
                        observer.next(selectors);
                    },
                    error: () => {
                        observer.next(undefined);
                    }
                });
        });
    }

    public getAppointmentPage (request: AppointmentSearchCriteria): Observable<AppointmentPage>{
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.AppointmentList}`;

        return this.http.post<AppointmentPage>(url, request, this.options);
    }

    public validateAllAppointmentPhones (request: AppointmentSearchCriteria): Observable<ChatAppPhoneLookupPage> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.AppointmentPhoneBatchValidation}`;

        return this.http.post<ChatAppPhoneLookupPage>(url, request, this.options);
    }

    public validateAppointmentPhone (appointmentId: number): Observable<EligibilityModel> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.AppointmentPhoneValidation}`;
        const options = {...this.options, params:  new HttpParams().set('appointmentId', appointmentId)};

        return this.http.get<EligibilityModel>(url, options);
    }

    public createPatientChat (request: InitiatePatientChatRequest): Observable<Object> {
        const url = `${environment.apiUrl}${ApiPaths.ApiExtension}/${this.sessionInfo.ClientId}/${ApiPaths.CreatePatientChat}`;

        return this.http.post(url, request, this.options);
    }
}

<div class="conversation-list-wrapper chat-panel">
    <ng-template [ngIf]="summaryService.loading" [ngIfElse]="viewport">
        <div class="loading-wrapper">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>

    <ng-template #viewport>
        <cdk-virtual-scroll-viewport *ngIf="summaryService.length > 0; else noChats" itemSize="130" class="virtual-viewport">
            <div *cdkVirtualFor="let chat of summaryService" [cdkContextMenuTriggerFor]="cardMenu">
                <ng-template #cardMenu>
                    <div class="card-menu" cdkMenu>
                        <button cdkMenuItem (click)="markReadUnread(chat)">Mark as {{ chat.isUnread ? "Read" : "Unread" }}</button>
                    </div>
                </ng-template>
                <app-conversation-card [cardInfo]="chat" [isSelected]="chat.isSelected" (click)="selectConversation($event, chat)"></app-conversation-card>
            </div>
        </cdk-virtual-scroll-viewport>
    </ng-template>

    <ng-template #noChats>
        <p class="no-chats">To start a conversation click New Chat.</p>
    </ng-template>
</div>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertConfiguration, AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';

@Component({
    selector: 'await-popover',
    templateUrl: './await-popover.component.html',
    styleUrls: ['./await-popover.component.scss', '../alert-popover/alert-popover.component.scss']
})
export class AwaitPopoverComponent extends AlertPopoverComponent {

    constructor(
        dialogRef: MatDialogRef<AlertPopoverComponent>,
        @Inject(MAT_DIALOG_DATA) data: AlertConfiguration,
    ) {
        super(dialogRef, data);
    }
}

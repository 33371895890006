import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SessionInfoService } from './services/session-info.service';
import { IconService } from './services/icon.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    public title = 'Patient Chat';

    constructor(
        private _sessionInfoService: SessionInfoService,
        private iconService: IconService) {
        this.iconService.registerIcons();
    }

    public ngOnInit(): void {
        this._sessionInfoService.ensureStarted();
    }
}

<div class="search-criteria">
    <div class="criteria-header"> {{criteriaTitle}} </div>
    <mat-select panelClass="criteria-dropdown-panel"
        (blur) = 'setErrorState()'
        (selectionChange) = 'setErrorState()'
        [ngClass]="errorState ? 'criteria-dropdown criteria-error':'criteria-dropdown'"
        [formControl]="form"
        [placeholder]="placeholderText">
        <mat-option *ngFor="let option of searchOptions" [value]="option">
            {{option.valueTitle}}
        </mat-option>
    </mat-select>

    <div *ngIf="errorState" class="criteria-error-text mat-small">
        {{errorMessage}}
    </div>
</div>

/**
 * Clears the time off a date in the user's time zone
 * @param date - The date you wish to clear the time from
 * @param daysFromDate - (Optional) Days from the date. Will update the cleared date x days from the given date
 * @returns The cleared date
 */
export function clearDate(date: Date, daysFromDate: number = 0): Date {
    const _clearDate: Date = new Date(date);
    _clearDate.setHours(0, 0, 0, 0);
    _clearDate.setDate(_clearDate.getDate() + daysFromDate);
    return _clearDate;
}

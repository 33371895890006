<ng-container *ngFor="let message of conversation.messages">

    <!-- DATE CHANGE -->
    <ng-container *ngIf="message.isDateChange">
        <div class="chat-date">
            <p>{{ message.messageDateTime | date : 'M/d/yy' }}</p>
        </div>
    </ng-container>

    <ng-container [ngSwitch]="message.isUser">
        <!-- FROM PATIENT - BEGIN -->
        <ng-container *ngSwitchCase="false">
                <div class="received-msg message-wrapper">
                    <div class="title">
                        {{ message.messageDateTime | date : 'h:mm a' }}
                    </div>
                    <p>{{ message.message }}</p>
                </div>
        </ng-container>
        <!-- FROM PATIENT - END -->

        <!-- FROM USER APP - BEGIN-->
        <ng-container *ngSwitchCase="true">
            <div class="outgoing-chats">
                <div class="outgoing-msg">

                    <ng-container [ngSwitch]="message.displayType">
                        <!-- SEND SUCCESS - BEGIN -->
                        <ng-container *ngSwitchCase="messageDisplayTypeEnums.Success">

                            <div class="outgoing-chats-msg outgoing-chats-margin message-wrapper">
                                <div class="title">
                                    <ng-container [ngSwitch]="message.isAutoReply">
                                        <ng-container *ngSwitchCase="true">
                                            <div><mat-icon class="material-symbols-outlined">arrow_circle_left</mat-icon>{{ message.senderName }}</div>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="false">
                                            {{ message.senderName }}
                                        </ng-container>
                                    </ng-container>

                                    <div>{{ message.messageDateTime | date : 'h:mm a' }}</div>
                                </div>

                                <p>{{ message.message }}</p>

                                <span class="delivered">{{ message.deliveryMsg }}</span>
                            </div>
                        </ng-container>
                            <!-- SEND SUCCESS - END -->

                        <!-- SEND FAILURE - BEGIN -->
                        <ng-container *ngSwitchCase="messageDisplayTypeEnums.Failure">
                            <div class="outgoing-chats-msg-error outgoing-chats-margin message-wrapper">
                                <div class="title">
                                    <ng-container [ngSwitch]="message.isAutoReply">
                                        <ng-container *ngSwitchCase="true">
                                            <div><mat-icon class="material-symbols-outlined">arrow_circle_left</mat-icon>{{ message.senderName }}</div>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="false">
                                            {{ message.senderName }}
                                        </ng-container>
                                    </ng-container>
                                    <div>{{ message.messageDateTime | date : 'h:mm a' }}</div>
                                </div>
                                <p>{{ message.message }}</p>
                                <ng-container [ngSwitch]="message.status">
                                    <ng-container *ngSwitchCase=MessageStatus.OptedOut>
                                    </ng-container>
                                </ng-container>

                                <div class="deliveryError">
                                    <mat-icon subject class="message-icon" svgIcon="{{message.iconName}}" />
                                    {{ message.deliveryMsg }}
                                </div>
                            </div>
                        </ng-container>
                        <!-- SEND FAILURE - END -->
                    </ng-container>

                </div>
            </div>
        </ng-container>
        <!-- FROM USER APP - END -->

        <!-- CHAT CLOSED -->
        <ng-container [ngSwitch]="message.closedDisplay">
            <ng-container *ngSwitchCase="messageDisplayTypeEnums.ClosedAutomatically">
                <div class="chat-status">
                    <h4><mat-icon svgIcon="Locked" class="material-icons-lock"></mat-icon>CHAT IS CLOSED</h4>
                    <p><i>closed automatically on {{ conversation.closedOn | date : 'M/d/yy' }} at {{ conversation.closedOn | date : 'h:mm a' }}</i></p>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="messageDisplayTypeEnums.ClosedManually">
                <div class="chat-status">
                    <h4><mat-icon svgIcon="Locked" class="material-icons-lock"></mat-icon><div class="closed-text">CHAT IS CLOSED</div></h4>
                    <p><i>closed by {{ conversation.closedBy | name:true:true }} on {{ conversation.closedOn | date : 'M/d/yy' }} at {{ conversation.closedOn | date : 'h:mm a' }}</i></p>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

</ng-container>

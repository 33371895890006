import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionInfo, SessionInfoService } from './session-info.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CareNarrativeOptions } from 'src/app/shared/interfaces/care-narrative-options.interface';

@Injectable({
    providedIn: 'root'
})
export class CareNarrativeServiceBase {

    protected sessionLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private static missingSessionError = 'Session info not available. Consider using the sessionLoaded BehaviorSubject to await a loaded session';

    private _sessionInfo: SessionInfo | null = null;

    constructor(
        protected http: HttpClient,
        protected sessionInfoService: SessionInfoService
    ) {
        sessionInfoService.CurrentSession.subscribe(session => {
            if(session) {
                this._sessionInfo = session;
                this.sessionLoaded.next(true);
            }
        });
    }

    protected get options (): CareNarrativeOptions {
        if(this.sessionInfo) {
            return {
                headers: this.careNarrativeHeader
            };
        }
        return {} as CareNarrativeOptions;
    }

    protected get sessionInfo(): SessionInfo {
        if (!this._sessionInfo) {
            throw new Error(CareNarrativeServiceBase.missingSessionError);
        }
        return this._sessionInfo;
    }

    protected get careNarrativeHeader () {
        if (!this._sessionInfo) {
            throw new Error(CareNarrativeServiceBase.missingSessionError);
        }
        return new HttpHeaders().set('Authorization', 'Bearer ' + this._sessionInfo.Authtoken);
    }
}

import { Component, ChangeDetectionStrategy} from '@angular/core';

/* Tool Tip Component Usage Example:
  <tooltip>
    <div subject class="page-header" > Search By Appointment </div>
    <span tooltip><b>The Title of this Page</b></span>
  </tooltip>

  "subject" & "tooltip" tags are required to denote which subcomponent is displayed and which is hidden by default.
*/

@Component({
    selector: 'tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ToolTipComponent {

    public isOpen = false;

    public enter() {
        this.isOpen = true;
    }

    public exit() {
        this.isOpen = false;
    }
}

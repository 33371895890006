export enum ApiPaths {
    ApiExtension = '/api/clients',
    AppointmentList = 'Appointment/Appointment',
    AppointmentPhoneValidation = 'Eligibility/eligibility',
    AppointmentPhoneBatchValidation = 'Chat/lookup',
    AppointmentStatus = 'Appointment/GetAppointmentStatuses',
    AppointmentTypes = 'Appointment/GetAppointmentTypes',
    CreatePatientChat = 'Chat/chat',
    Locations = 'Appointment/GetLocations',
    Providers = 'Appointment/GetProviders',
    Suborganizations = 'Appointment/GetSuborganizations',
}

export enum PatientChatPaths {
    ChatSummary = '/{clientId}/Chat/getChats',
    GetConversation = '/{clientId}/Chat/{conversationId}',
    SendMessage = '/{clientId}/Chat/{conversationId}/message',
    CloseReadChat = '/{clientId}/Chat',
    JoinLeaveConversation = '/{clientId}/ConversationGroupMembership/{conversationId}',
}

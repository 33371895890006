import { ChangeDetectionStrategy,  ChangeDetectorRef,  Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { SessionInfoService } from 'src/app/services/session-info.service';
import { ChatMessageService } from 'src/app/services/chat-message.service';
import { ConversationCard } from 'src/app/shared/interfaces/conversation-card.interface';
import { ConversationInfo } from 'src/app/shared/interfaces/conversation-info.interface';
import { PatientChatConversationUpdate } from 'src/app/shared/interfaces/patient-chat-conversation-update.interface';
import { AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { ErrorAlerts } from 'src/app/shared/enums/error-alert.enum';

@Component({
    selector: 'app-patient-chat',
    templateUrl: './patient-chat.component.html',
    styleUrls: ['./patient-chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientChatComponent implements OnDestroy, OnInit {

    public chatList: ConversationCard[] = [];

    public loadingConversation = false;

    public selectedChat?: ConversationInfo | undefined;

    public messagesLoaded: Subject<boolean> = new Subject();

    private chatList$?: Subscription;

    private conversation$?: Subscription;

    constructor(
        private dialog: MatDialog,
        private sessionInfoService: SessionInfoService,
        private messageService: ChatMessageService,
        private changeDetector: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.messageService.updatedMessageStream.subscribe({
            next: (value: PatientChatConversationUpdate) => {
                if (value.updatedMessage) {
                    this.upsertChatMessage(value);
                }
                if (value.statusUpdate) {
                    this.updateClosedStatus(value);
                }
            }
        });
        this.sessionInfoService.updateBreadcrumb(['Campaigns', 'Patient Chat']);
    }

    public chatSelected(chat: ConversationCard | null): void {
        if (chat) {
            this.loadingConversation = true;

            this.conversation$ = this.messageService.getConversation(chat.conversationId).subscribe(
                {
                    next: (response: ConversationInfo | undefined) => {
                        if (response) {
                            this.selectedChat = response;
                        }
                        this.loadingConversation = false;
                        this.changeDetector.detectChanges();
                        this.messagesLoaded.next(true);
                    },
                    error: () => this.onApiError()
                }
            );
        } else {
            this.selectedChat = undefined;
        }
    }

    private upsertChatMessage(update: PatientChatConversationUpdate) {
        if (this.selectedChat) {
            const existingMessageIndex = this.selectedChat.messages.findIndex(message => message.id === update.updatedMessage.id);
            if (existingMessageIndex > -1) {
                this.selectedChat.messages[existingMessageIndex] = update.updatedMessage;
            } else {
                this.selectedChat.messages.push(update.updatedMessage);
            }
            // need to copy to trigger onChanges in child components
            this.selectedChat = {...this.selectedChat};
        }
    }

    private updateClosedStatus(update: PatientChatConversationUpdate) {
        if (this.selectedChat) {
            this.selectedChat.closedBy = update.statusUpdate.closedBy;
            this.selectedChat.closedOn = update.statusUpdate.closedOn;
            this.selectedChat.isOpen = update.statusUpdate.isOpen;
            // need to copy to trigger onChanges in child components
            this.selectedChat = {...this.selectedChat};
        }
    }

    public ngOnDestroy(): void {
        this.chatList$?.unsubscribe();
        this.conversation$?.unsubscribe();
    }

    private onApiError(): void {
        this.dialog.open(AlertPopoverComponent, {
            data: ErrorAlerts.PageError,
            width: AlertPopoverComponent.defaultWidth,
        });
    }
}

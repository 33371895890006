import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientsShownInHeader } from 'src/app/shared/enums/defaults.enum';
import { ChatMessageService } from 'src/app/services/chat-message.service';
import { ConversationInfo } from 'src/app/shared/interfaces/conversation-info.interface';
import { PatientAppointment } from 'src/app/shared/interfaces/patient-appointment.interface';
import { MatDialog } from '@angular/material/dialog';
import { AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { ErrorAlerts } from 'src/app/shared/enums/error-alert.enum';
import { ActivePatchModel } from 'src/app/shared/interfaces/active-patch-model.interface';

@Component({
    selector: 'app-conversation-window-header',
    templateUrl: './conversation-window-header.component.html',
    styleUrls: ['./conversation-window-header.component.scss']
})
export class ConversationWindowHeaderComponent {

    @Output() public chatClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public chatInfo!: ConversationInfo;

    public activePatchModel: ActivePatchModel | undefined;

    // Display the maximum amount patients, until the user clicks the "View x more" button
    public readonly defaultPatientsShown: number = PatientsShownInHeader;

    public infoIsOpen = false;

    public infoToShow!: PatientAppointment;

    public patientsShown: number = PatientsShownInHeader;

    public triggerOrigin: any;

    constructor(
        private dialog: MatDialog,
        private messageService: ChatMessageService
    ) {}

    public clickOutside(): void {
        this.infoIsOpen = false;
    }

    public closeChat(): void {
        this.activePatchModel = { chatId: this.chatInfo.conversationId, isOpen: false };
        this.messageService.closeChat(this.activePatchModel)
            .subscribe({
                next: () => {
                    this.chatInfo.isOpen = false;
                    this.chatClosed.emit(true);
                },
                error: () => {
                    this.dialog.open(AlertPopoverComponent, {
                        data: ErrorAlerts.PageError,
                        width: AlertPopoverComponent.defaultWidth,
                    });
                }
            });
    }

    public toggleInfo(trigger: MouseEvent, appt: PatientAppointment): void {
        this.triggerOrigin = trigger.target;
        this.infoToShow = appt;
        this.infoIsOpen = !this.infoIsOpen;
    }

    public viewLess(): void {
        this.patientsShown = this.defaultPatientsShown;
    }

    public viewMore(): void {
        this.patientsShown = this.chatInfo.patients.length;
    }

}

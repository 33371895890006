<div [ngClass]="'date-picker-spacer'">
    <div class="criteria-header"> {{criteriaTitle}} </div>
    <div [ngClass]="errorState ? 'criteria-dropdown criteria-error date-picker-box':'criteria-dropdown date-picker-box'">
        <input matInput
            (blur) = 'setErrorState()'
            [formControl]="form"
            [matDatepicker]="picker">
        <mat-datepicker #picker/>
        <mat-datepicker-toggle matIconSuffix
            [for]="picker"
            [disableRipple]="true">
            <mat-icon svgIcon="Calendar" matDatepickerToggleIcon></mat-icon>
        </mat-datepicker-toggle>
    </div>
    <div *ngIf="errorState" class="criteria-error-text">
        {{errorMessage}}
    </div>
</div> 
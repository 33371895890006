import { Pipe, PipeTransform } from '@angular/core';
import { PatientAppointment } from '../interfaces/patient-appointment.interface';

@Pipe({
    name: 'sortAppointments'
})
export class SortAppointmentsPipe implements PipeTransform {

    public transform(appointments: PatientAppointment[]): PatientAppointment[] {
        return appointments.sort((a, b) => {
            // Alphabetize by last name and then first name
            const aName = a.patientName.lastName + a.patientName.firstName;
            const bName = b.patientName.lastName + b.patientName.firstName;

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });
    }

}

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectorOption } from '../combo-selector/combo-selector.component';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';

@Component({
    selector: 'single-selector',
    templateUrl: './single-selector.component.html',
    styleUrls: ['./single-selector.component.scss', '../combo-selector/combo-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class SingleSelectorComponent{

    @Input()
    public criteriaTitle = '';

    @Input()
    public errorMessage = 'Default Error';

    @Input()
    public form: FormControl<SelectorOption | null> = new FormControl(null, {nonNullable: true});

    @Input()
    public placeholderText = '';

    @Input()
    public searchOptions: SelectorOption[] = [];

    private _errorState = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private appointmentService: AppointmentSearchService) {
        this.appointmentService.checkSearchErrorsSubject.subscribe(
            () => {
                this.setErrorState();
            }
        );
    }

    public setErrorState() {
        this._errorState = this.form.errors != null;
        this.changeDetector.markForCheck();
    }

    public get errorState(): boolean {
        return this._errorState;
    }
}

import { AlertConfiguration } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';

export class ErrorAlerts {

    public static get PageError(): AlertConfiguration {
        return {
            alertTitle: 'Error Requesting Data',
            alertText: 'We\'re having trouble requesting data right now, please try again later. If this problem persists, please contact support.',
        };
    }

    public static get ConversationError(): AlertConfiguration {
        return {
            alertTitle: 'Error Updating Conversation',
            alertText: 'We\'re having trouble updating conversation data, please try again later. If this problem persists, please contact support.',
        };
    }

    public static get SendError(): AlertConfiguration {
        return {
            alertTitle: 'Error Sending Message',
            alertText: 'We\'re having trouble sending text messages right now, please try again later. If this problem persists, please contact support.',
        };
    }

    public static get CriteriaError(): AlertConfiguration {
        return {
            alertTitle: 'Refine Search Criteria',
            alertText: 'Not all required search criteria have been selected. Please select at least one value for all required fields.',
        };
    }

    public static get PleaseWaitMessage(): AlertConfiguration {
        return {
            alertTitle: 'Please Wait',
            alertText: 'Checking all appointments for valid Phone Numbers...',
        };
    }

    public static get NoAppointmentsSelectedError(): AlertConfiguration {
        return {
            alertTitle: 'No Appointments Selected',
            alertText: 'Please select at least one appointment to send a message.',
        };
    }
}

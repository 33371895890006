import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent{

    @Input()
    public characterLimit: number | null = null;

    @Input()
    public counterLocation = 'None';

    @Input()
    public displayCharacterLimit = false;

    @Input()
    public header: string | null = null;

    @Input()
    public placeholderText = '';

    @Input()
    public textlines = 3;

    // In-Out Variable
    @Input()
    public value: String = '';

    @Output()
    public valueChange = new EventEmitter<String>();

    constructor() { }

    public get characterCount(): number {
        return this.value.length;
    }

    public valueUpdate(): void {
        this.valueChange.emit(this.value ?? '');
    }

}

import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { ErrorAlerts } from 'src/app/shared/enums/error-alert.enum';
import { ChatMessageService } from 'src/app/services/chat-message.service';
import { ChatSummaryService } from 'src/app/services/chat-summary.service';
import { ConversationInfo } from 'src/app/shared/interfaces/conversation-info.interface';

@Component({
    selector: 'app-conversation-window',
    templateUrl: './conversation-window.component.html',
    styleUrls: ['./conversation-window.component.scss'],
})
export class ConversationWindowComponent implements OnDestroy, OnInit, AfterViewChecked {

    @Input()
    public chat?: ConversationInfo;

    @Input()
    public loading?: boolean;

    @Input()
    public messagesLoaded!: Observable<boolean>;

    public message: String = '';

    private readonly subscription = new Subscription();

    public errorMessage = 'Message is required.';

    private _errorState = false;

    private _sendDisabled = false;

    constructor (
        private changeDetector: ChangeDetectorRef,
        public dialog: MatDialog,
        private messageService: ChatMessageService,
        private summaryService: ChatSummaryService
    ) {}

    public ngAfterViewChecked(): void {
        // When the view changes, if we are within a message of the bottom, scroll to the bottom.
        const chatMessagesContainer = document.getElementById('chatMessages');
        if (chatMessagesContainer) {
            const lastChild = chatMessagesContainer.children[chatMessagesContainer.children.length - 1];
            const scrollBottom = chatMessagesContainer.scrollTop + chatMessagesContainer.clientHeight;
            let newHeight = this.getTotalHeight(lastChild);

            // Adding the new message may have also added a date line. If so, add that as part of the new height.
            if (lastChild.previousElementSibling &&
                    lastChild.previousElementSibling.classList.contains('chat-date')) {
                newHeight += this.getTotalHeight(lastChild.previousElementSibling);
            }

            // If the scroll bottom, and the newly added height is more than the total scroll height, we should scroll to the bottom.
            if (scrollBottom + newHeight >= chatMessagesContainer.scrollHeight) {
                chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
            }
        }
    }

    private getTotalHeight(element: Element): number {
        const computedStyle = window.getComputedStyle(element, null);
        let height = element.clientHeight;
        height += parseFloat(computedStyle.marginTop);
        height += parseFloat(computedStyle.marginBottom);
        return height;
    }

    public ngOnInit(): void {
        const scrollSubscription = this.messagesLoaded.subscribe({
            next: (loaded: boolean) => {
                if (loaded === true) {
                    this.scrollToBottom();
                }
            },
        });
        this.subscription.add(scrollSubscription);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public setErrorState() {
        this._errorState = this.message === '';
        this.changeDetector.markForCheck();
    }

    public get errorState(): boolean {
        return this._errorState;
    }

    public async sendMessage() {
        this.setErrorState();
        if (this._sendDisabled) {
            return;
        }

        if (!this._errorState && this.chat) {
            this.summaryService.setSelectedAsRead();
            this._sendDisabled = true;
            this.messageService.sendMessage({ text: this.message.toString() }, this.chat.conversationId)
                .subscribe({
                    next: () => {
                        this.message = '';
                        this.changeDetector.detectChanges();
                        this._sendDisabled = false;
                    },
                    error: () => {
                        this.dialog.open(AlertPopoverComponent, {
                            data: ErrorAlerts.SendError,
                            width: AlertPopoverComponent.defaultWidth,
                        });
                        this._sendDisabled = false;
                    }
                });
        }
    }

    private scrollToBottom(): void {
        const chatMessagesContainer = document.getElementById('chatMessages');
        if (chatMessagesContainer) {
            chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
        }
    }
}

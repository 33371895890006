export enum Icons {
    ArrowClockwise = 'Arrow-Clockwise',
    BlackArrow = 'Black-Arrow',
    Calendar = 'Calendar',
    CheckboxChecked = 'Checkbox-Checked',
    CheckboxIndeterminate = 'Checkbox-Indeterminate',
    CheckboxUnchecked = 'Checkboc-Unchecked',
    CloseIcon = 'Close-Icon',
    InfoClear = 'Info-Clear',
    LoadingSpinner = 'Loading-Spinner',
    Locked = 'Locked',
    MoveBack = 'Move-Back',
    MoveForward = 'Move-Forward',
    MoveToBack = 'Move-To-Back',
    MoveToFront = 'Move-To-Front',
    MsgUsers = 'Msg-Users',
    OptOut = 'OptOut',
    PhoneError = 'Phone-Error',
    SendArrow = 'Send-Arrow',
    SendError = 'Send-Error',
    Unlocked = 'Unlocked'
}

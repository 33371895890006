import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';

export interface SelectorOption{
    value: Object | null;
    valueTitle: string;
}

@Component({
    selector: 'combo-selector',
    templateUrl: './combo-selector.component.html',
    styleUrls: ['./combo-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ComboSelectorComponent{

    @Input()
    public criteriaTitle = '';

    @Input()
    public errorMessage = 'Default Error';

    @Input()
    public form: FormControl<SelectorOption[] | null> = new FormControl([], {nonNullable: true});

    @Input()
    public placeholderText = '';

    @Input()
    public plural: String = 'Default Plurals';

    @Input()
    public searchOptions: SelectorOption[] = [];

    private _errorState = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private appointmentService: AppointmentSearchService) {
        this.appointmentService.checkSearchErrorsSubject.subscribe(
            () => {
                this.setErrorState();
            }
        );
    }

    public setErrorState() {
        this._errorState = this.form.errors != null;
        this.changeDetector.markForCheck();
    }

    public get errorState(): boolean {
        return this._errorState;
    }

    public isChecked(): boolean {
        return this.form.value !== null && this.form.value.length === this.searchOptions.length;
    }

    public isIndeterminate(): boolean {
        return this.form.value !== null && this.form.value.length < this.searchOptions.length && this.form.value.length > 0;
    }

    public toggleAllSelection(): void {
        if (this.form.value !== null && this.form.value.length !== this.searchOptions.length){
            this.form.setValue([]);
            this.form.patchValue(this.searchOptions);
        } else {
            this.form.setValue([]);
        }
    }
}

export enum MessageStatus {
    Unknown,
    Uninterpreted,
    Sent,
    Error,
    Response,
    DeliveredToCarrier,
    AcceptedByCarrier,
    DeliveredToHandset,
    OptedOut,
    OptedIn,
    InvalidNumber
}

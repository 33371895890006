import { Injectable } from '@angular/core';
import { ConversationInfo, MessageSendRequest } from 'src/app/shared/interfaces/conversation-info.interface';
import { environment } from 'src/environments/environment';
import { ApiPaths, PatientChatPaths } from 'src/app/shared/enums/api-paths.enum';
import { Observable, ReplaySubject, Subject, Subscriber, Subscription, concatMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionInfoService } from './session-info.service';
import { CareNarrativeServiceBase } from './care-narrative-service-base.service';
import { CloseConversation } from 'src/app/shared/interfaces/close-conversation.interface';
import { ActivePatchModel } from 'src/app/shared/interfaces/active-patch-model.interface';
import { PatientChatConversationUpdate } from '../shared/interfaces/patient-chat-conversation-update.interface';
import { ChatSummaryEvent, ChatSummaryEventType } from 'src/app/shared/interfaces/chat-summary-event.interface';

@Injectable({
    providedIn: 'root'
})
export class ChatMessageService extends CareNarrativeServiceBase {

    private readonly REPLAY_COUNT = 1;

    public readonly updatedMessageStream = new ReplaySubject<PatientChatConversationUpdate>(this.REPLAY_COUNT);

    private readonly signalRSubscription = new Subscription();

    private summaryUpdateSubject = new Subject<ChatSummaryEvent> ();

    constructor(
        protected override http: HttpClient,
        protected override sessionInfoService: SessionInfoService
    ) {
        super(http, sessionInfoService);
        // keeps calls to update sequential instead of concurrent.
        this.signalRSubscription.add(
            this.summaryUpdateSubject.pipe(concatMap(
                (request: ChatSummaryEvent) => this.processUpdate(request))
            ).subscribe()
        );
        this.signalRSubscription.add(
            this.sessionInfoService.PatientChatConversationUpdate.subscribe(
                (request: PatientChatConversationUpdate) => this.scheduleConversationUpdate(request)
            )
        );
    }

    public markReadUnread(conversationId: number, unreadStatus: boolean) {
        const activePatchModel: ActivePatchModel = {chatId: conversationId, isUnread: unreadStatus};
        const url = environment.apiUrl + ApiPaths.ApiExtension +
            PatientChatPaths.CloseReadChat.replace(
                '{clientId}', this.sessionInfo.ClientId ?? ''
            );
        return this.http.patch<CloseConversation>(url, activePatchModel, this.options);
    }

    public closeChat(activePatchModel: ActivePatchModel): Observable<CloseConversation> {
        const url = environment.apiUrl + ApiPaths.ApiExtension +
            PatientChatPaths.CloseReadChat.replace(
                '{clientId}', this.sessionInfo.ClientId ?? ''
            );

        return this.http.patch<CloseConversation>(url, activePatchModel, this.options);
    }

    public getConversation(conversationId: number): Observable<ConversationInfo | undefined> {
        const url = environment.apiUrl + ApiPaths.ApiExtension +
            PatientChatPaths.GetConversation.replace(
                '{conversationId}', conversationId.toString()).replace('{clientId}', this.sessionInfo.ClientId ?? ''
            );

        return this.http.get<ConversationInfo>(url, this.options);
    }

    public sendMessage(message: MessageSendRequest, conversationId: number): Observable<void> {
        const url = environment.apiUrl + ApiPaths.ApiExtension +
            PatientChatPaths.SendMessage.replace(
                '{conversationId}', conversationId.toString()).replace('{clientId}', this.sessionInfo.ClientId ?? ''
            );
        return this.http.post<void>(url, message, this.options);
    }

    public leaveConversation(conversationId: number): Observable<void> {
        const url = environment.apiUrl + ApiPaths.ApiExtension + PatientChatPaths.JoinLeaveConversation.replace('{conversationId}', conversationId.toString()).replace('{clientId}', this.sessionInfo.ClientId ?? '');

        return this.http.delete<void>(url, this.options);
    }

    public joinConversation(conversationId: number): Observable<void> {
        const url = environment.apiUrl + ApiPaths.ApiExtension + PatientChatPaths.JoinLeaveConversation.replace('{conversationId}', conversationId.toString()).replace('{clientId}', this.sessionInfo.ClientId ?? '');

        return this.http.post<void>(url, {}, this.options);
    }

    private processUpdate(event: ChatSummaryEvent): Observable<ChatSummaryEvent>{
        return new Observable((subscriber: Subscriber<any>) => {
            if (event.eventType === ChatSummaryEventType.IncrementalUpdate){
                this.processConversationUpdate(event.data as PatientChatConversationUpdate).subscribe({
                    complete: () => {
                        subscriber.next(event);
                        subscriber.complete();
                    }
                });
            }
        });
    }

    private processConversationUpdate(update: PatientChatConversationUpdate): Observable<any> {
        return new Observable((subscriber: Subscriber<any>) => {
            this.updatedMessageStream.next(update);
            subscriber.complete();
        });
    }

    private scheduleConversationUpdate(update: PatientChatConversationUpdate){
        const event: ChatSummaryEvent = {
            eventType: ChatSummaryEventType.IncrementalUpdate,
            data: update,
        };
        this.summaryUpdateSubject.next(event);
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, first } from 'rxjs';
import { AlertPopoverComponent } from 'src/app/components/common/popovers/alert-popover/alert-popover.component';
import { ChatMessageService } from 'src/app/services/chat-message.service';
import { ChatSummaryService } from 'src/app/services/chat-summary.service';
import { ErrorAlerts } from 'src/app/shared/enums/error-alert.enum';
import { ConversationCard } from 'src/app/shared/interfaces/conversation-card.interface';

@Component({
    selector: 'app-conversation-list',
    templateUrl: './conversation-list.component.html',
    styleUrls: ['./conversation-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationListComponent implements OnDestroy, OnInit {

    @Output()
    public selectChat: EventEmitter<ConversationCard | null> = new EventEmitter<ConversationCard | null>();

    private subscriptions$ = new Subscription();

    constructor (
        public summaryService: ChatSummaryService,
        public conversationService: ChatMessageService,
        public dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
    ) {
    }

    public markReadUnread(chat: ConversationCard) {
        const readStatus = !chat.isUnread;
        this.conversationService.markReadUnread(chat.conversationId, readStatus).subscribe({
            next: () => {
                chat.isSelected = false;
                chat.isUnread = readStatus;
                this.changeDetector.detectChanges();
            },
            error: () => {
                this.dialog.open(AlertPopoverComponent, {
                    data: ErrorAlerts.ConversationError,
                    width: AlertPopoverComponent.defaultWidth,
                });
            }
        });
    }

    public deselectConversation(selectedConversation: ConversationCard | undefined) {
        // De-select any already selected chats and consider them as having been read.
        if(selectedConversation !== undefined) {
            const conversationId = selectedConversation.conversationId;
            // We want this to finish even after we have destroyed the component, first() will automatically unsubscribe.
            this.conversationService.leaveConversation(conversationId).pipe(first()).subscribe({
                next: () => {
                    // eslint-disable-next-line no-console
                    console.log('left conversation - ' + conversationId);
                },
                error: () => {
                    this.dialog.open(AlertPopoverComponent, {
                        data: ErrorAlerts.ConversationError,
                        width: AlertPopoverComponent.defaultWidth,
                    });
                }
            });
            selectedConversation.isSelected = false;
            if(selectedConversation.isUnread) {
                this.conversationService.markReadUnread(selectedConversation.conversationId, false).subscribe({
                    next: () => {
                        selectedConversation.isUnread = false;
                        this.changeDetector.detectChanges();
                    },
                    error: () => {
                        this.dialog.open(AlertPopoverComponent, {
                            data: ErrorAlerts.ConversationError,
                            width: AlertPopoverComponent.defaultWidth,
                        });
                    }

                });
            }
        }
    }

    public selectConversation($event: any, conv: ConversationCard) {
        const selectedConversation = this.summaryService.selectedConversation;

        if ($event.ctrlKey) {
            this.deselectConversation(selectedConversation);
            this.selectChat.emit(null);
            return;
        }

        if (selectedConversation === conv) {
            // We don't need to join a conversation we're already in.
            return;
        }
        this.deselectConversation(selectedConversation);

        conv.isSelected = true;
        const conversationJoin = (this.conversationService.joinConversation(conv.conversationId).subscribe({
            next: () => {
                // eslint-disable-next-line no-console
                console.log('Joined conversation - ' + conv.conversationId);
            },
            error: () => {
                // eslint-disable-next-line no-console
                this.dialog.open(AlertPopoverComponent, {
                    data: ErrorAlerts.ConversationError,
                    width: AlertPopoverComponent.defaultWidth,
                });
            }
        }));
        this.subscriptions$.add(conversationJoin);
        this.selectChat.emit(conv);
    }

    public ngOnInit(): void {
        this.summaryService.dataStream.subscribe(() => this.changeDetector.detectChanges());
    }

    public ngOnDestroy(): void {
        this.deselectConversation(this.summaryService.selectedConversation);
        this.subscriptions$.unsubscribe();
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePath } from 'src/app/shared/enums/route-path.enum';
import { PatientChatComponent } from 'src/app/components/patient-chat/patient-chat.component';
import { CreateChatComponent } from 'src/app/components/create-chat/create-chat.component';
const routes: Routes = [

    { path: `${RoutePath.Create}`, pathMatch: 'full', component: CreateChatComponent },
    { path: `${RoutePath.Default}`, pathMatch: 'full', component: PatientChatComponent },
    { path: `${RoutePath.Redirect}`, redirectTo: RoutePath.Default },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

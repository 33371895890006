import { Component, Input, OnInit } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { SessionInfo, SessionInfoService } from 'src/app/services/session-info.service';
import { ErrorState } from 'src/app/shared/enums/error-state.enum';
import { ConversationCard } from 'src/app/shared/interfaces/conversation-card.interface';
import { PatientAppointment } from 'src/app/shared/interfaces/patient-appointment.interface';

enum DotClasses {
    None = 'dot-none',
    Error = 'dot-error',
    Unread = 'dot-msg'
}

@Component({
    selector: 'app-conversation-card',
    templateUrl: './conversation-card.component.html',
    styleUrls: ['./conversation-card.component.scss']
})
export class ConversationCardComponent implements OnInit {
    @Input()
    public cardInfo?: ConversationCard;

    @Input()
    public isSelected = false;

    public readonly ErrorStates = ErrorState;

    public otherPatients = 0;

    public sessionInfo: Observable<SessionInfo>;

    constructor(
        private sessionInfoService: SessionInfoService
    ) {
        this.sessionInfo = sessionInfoService.CurrentSession.pipe(filter(ConversationCardComponent.isNonNull));
    }

    public getDotClass(): string {
        if (this.cardInfo?.isUnread) {
            if (this.cardInfo?.errorState === ErrorState.OptedOut || this.cardInfo?.errorState === ErrorState.FailedToSend) {
                return DotClasses.Error;
            }

            return DotClasses.Unread;
        }

        return DotClasses.None;
    }

    public getOtherPatientCount(): void {
        let names = '';

        // Concatenate the names of the appointments
        this.cardInfo?.patients.every((patient: PatientAppointment, index) =>{
            names += patient.patientName.lastName + ' ' + patient.patientName.firstName + '; ';

            // If this name list is longer than this (guesstimated) max value,
            // determine how many names weren't added to the list and terminate the loop
            if (names.length > 80 && this.cardInfo) {
                this.otherPatients = this.cardInfo.patients.length - 1 - index;
                return false;
            }
            return true;
        });
    }

    public ngOnInit(): void {
        this.getOtherPatientCount();
    }

    private static isNonNull<T>(value: T): value is NonNullable<T> {
        return value != null;
    }
}

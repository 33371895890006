import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { DashboardModuleMessage, DashboardHostMessage, MessagePortLike, MessageTarget, MPE_TOKEN_NAME } from '@fmh/carenarrativemodule';
import { PatientChatMessage } from '../shared/interfaces/message-type-map';
import { LogService } from './log.service';
import { PatientChatCardUpdate } from '../shared/interfaces/patient-chat-card-update.interface';
import { PatientChatConversationUpdate } from '../shared/interfaces/patient-chat-conversation-update.interface';

export interface SessionInfo {
    ClientId: string;
    Authtoken: string;
}

// The message port that we will use to send messages to the host
export const HOST_TARGET_TOKEN = new InjectionToken<MessageTarget<DashboardModuleMessage>>('HOST_TARGET_TOKEN');

// The message port that we will use to receive messages from the host
export const MESSAGE_SOURCE_TOKEN = new InjectionToken<MessagePortLike>('MESSAGE_SOURCE_TOKEN');

@Injectable({
    providedIn: 'root'
})
export class SessionInfoService {
    private static MODULE_NAME = 'patientchat';

    private _currentSession: BehaviorSubject<SessionInfo | null>;

    private _started = false;

    public PatientChatCardUpdate: Subject<PatientChatCardUpdate> = new Subject<PatientChatCardUpdate>();

    public PatientChatConversationUpdate: Subject<PatientChatConversationUpdate> = new Subject<PatientChatConversationUpdate>();

    constructor(@Inject(HOST_TARGET_TOKEN) private _hostTarget: MessageTarget<DashboardModuleMessage>,
        @Inject(MESSAGE_SOURCE_TOKEN) private _messageSource: MessagePortLike,
        private _logService: LogService) {
        this._currentSession = new BehaviorSubject<SessionInfo | null>(null);
    }

    public get CurrentSession(): Observable<SessionInfo | null> {
        return this._currentSession.asObservable();
    }

    public set CurrentSession(newSession: SessionInfo) {
        this._currentSession.next(newSession);
    }

    public updateBreadcrumb(breadcrumbParts: string[]): void {
        this._hostTarget.postMessage({
            messageName: 'MODULE_BREADCRUMB_CHANGE',
            moduleName: SessionInfoService.MODULE_NAME,
            parts: breadcrumbParts
        }, { targetOrigin: '*' });
    }

    public ensureStarted(): void {
        if (this._started) {
            return;
        }

        this._started = true;
        this._messageSource.addEventListener('message', this._handleDashboardMessage.bind(this));

        this._hostTarget.postMessage({
            messageName: 'REQUEST_APPLICATION_INIT_DATA',
            moduleName: SessionInfoService.MODULE_NAME
        }, { targetOrigin: '*' });
    }

    /**
     * A handler for the DashboardHostMessages that are sent from the host to the module
     */
    private _handleDashboardMessage(this: SessionInfoService, event: MessageEvent<DashboardHostMessage>): void {
        if (event.data.messageName === 'APPLICATION_INIT_DATA') {
            const token = event.data.messageBody.Tokens.find(t => t.name === MPE_TOKEN_NAME);
            if (!token) {
                throw new Error('Could not find auth token');
            }
            const clientId = event.data.messageBody.ClientId.toString();

            this._currentSession.next({
                ClientId: clientId,
                Authtoken: token.value,
            });

            // this marks the start of the module's lifecycle, where we have an
            // auth token and can do stuff
            // now kick off any other initialization that needs to happen
        } else if (event.data.messageName === 'POST_TOKEN') {
            const token = event.data.messageBody.tokens.find(t => t.name === MPE_TOKEN_NAME);
            if (!token) {
                return;
            }
            this._partialSessionUpdate({ Authtoken: token.value });
        } else if (event.data.messageName === 'ORGANIZATION_CHANGED') {
            this._partialSessionUpdate({ClientId: event.data.messageBody.ClientId.toString()});
        } else if (event.data.messageName === 'SERVER_TO_MODULE') {
            this._handleServerMessage(event.data.messageBody as any as PatientChatMessage);
        }
    }

    private _partialSessionUpdate(update: Partial<SessionInfo>) {
        const previousSession = this._currentSession.getValue();

        if (!previousSession) {
            this._logService.trace('Received update before APPLICATION_INIT_DATA message, ignoring');
            return;
        }

        this._currentSession.next({
            ...previousSession,
            ...update,
        });
    }

    /**
     * A handler for the ServerMessages that are sent from the server to the module via the websocket connection
     */
    private _handleServerMessage(message: PatientChatMessage): void {
        if (message.messageType === 'PatientChatCardUpdate') {
            this.PatientChatCardUpdate.next(message.data);
        } else if (message.messageType === 'PatientChatConversationUpdate') {
            this.PatientChatConversationUpdate.next(message.data);
        }
    }
}

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { InitiatePatientChatRequest } from 'src/app/shared/interfaces/appointment-search.interface';
import { AppointmentSearchService } from 'src/app/services/appointment-search.service';

@Component({
    selector: 'create-message-options',
    templateUrl: './create-message-options.component.html',
    styleUrls: ['./create-message-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CreateMessageOptionsComponent {

    @Input()
    public disableSubmitButton = false;

    public message: String = '';

    @Input()
    public placeholderText = '';

    public errorMessage = 'Message is required.';

    private _errorState = false;

    @Output()
    public submitMessage: EventEmitter<InitiatePatientChatRequest> = new EventEmitter();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private appointmentService: AppointmentSearchService) {
        this.appointmentService.setMessageErrorSubject.subscribe(
            () => {
                this.setErrorState();
            }
        );
    }

    public setErrorState() {
        this._errorState = this.message === '';
        this.changeDetector.markForCheck();
    }

    public get errorState(): boolean {
        return this._errorState;
    }

    public sendMessage(): void {
        const messageOptions: InitiatePatientChatRequest = {
            MessageToSend: this.message.toString(),
            closeChatAfterSending: false,
            patientsToChatWith: [],
        };
        this.submitMessage.emit(messageOptions);
    }

}

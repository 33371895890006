<div class="selected-counter">
    {{selectedCount}} selected
</div>
<!-- Removed Header in latest AC
<div class="table-tool-header">
    <button (click)="refreshTable()" class="table-icon-button"> <img src="assets/icon/Arrow-Clockwise.svg"> </button>
</div>
-->
<table mat-table [dataSource]="dataSource" >
    <ng-container matColumnDef="Selector">
        <th mat-header-cell *matHeaderCellDef>
            <ng-container [ngSwitch]="allSelector">                
                <div *ngSwitchCase="selectorStatusEnums.Unselected">
                    <span [ngClass]="'select-box'"> <button (click)="selectAll()" class="table-icon-button"> <img src="assets/icon/Checkbox-Unchecked.svg"> </button> All </span>
                </div>
                <div *ngSwitchCase="selectorStatusEnums.Partial">
                    <span [ngClass]="'select-box'"> <button (click)="toggleSelection()" class="table-icon-button"> <img src="assets/icon/Checkbox-Indeterminate.svg"> </button> All </span>
                </div>
                <div *ngSwitchCase="selectorStatusEnums.Selected">
                    <span [ngClass]="'select-box'"> <button (click)="deselectAll()" class="table-icon-button"> <img src="assets/icon/Checkbox-Checked.svg"> </button> All </span>
                </div>
            </ng-container>
        </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-container [ngSwitch]="element.selector">
                <div *ngSwitchCase="selectorStatusEnums.Unselected">
                    <button (click)="selectAppointment(element.appointmentId)" class="table-icon-button"> <img src="assets/icon/Checkbox-Unchecked.svg"> </button>
                </div>
                <div *ngSwitchCase="selectorStatusEnums.Selected">
                    <button (click)="deselectAppointment(element.appointmentId)" class="table-icon-button"> <img src="assets/icon/Checkbox-Checked.svg"> </button>
                </div>
                <div *ngSwitchCase="selectorStatusEnums.Invalid">
                    <tooltip>
                        <img subject src="assets/icon/Phone-Error.svg" (click)="deselectAppointment(element.appointmentId)">
                        <span tooltip><b>Missing or Invalid number</b></span>
                    </tooltip>
                </div>
                <div [ngClass]="'loading-div'" *ngSwitchCase="selectorStatusEnums.Searching">
                    <mat-spinner [diameter]="20"/>
                </div>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="AppointmentDate">
        <th mat-header-cell *matHeaderCellDef> Appt Date </th>
        <td mat-cell *matCellDef="let element">
             <div>{{element.appointmentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}}</div>
             <div>{{element.appointmentDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric'})}}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="PatientName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.patientName}} </td>
    </ng-container>

    <ng-container matColumnDef="DateOfBirth">
        <th mat-header-cell *matHeaderCellDef> Date Of Birth </th>
        <td mat-cell *matCellDef="let element">
            {{element.dateOfBirth}}
        </td>
    </ng-container>

    <ng-container matColumnDef="PatientId">
        <th mat-header-cell *matHeaderCellDef> Patient ID </th>
        <td mat-cell *matCellDef="let element"> {{element.patientExternalId}} </td>
    </ng-container>
    
    <ng-container matColumnDef="ProviderName">
        <th mat-header-cell *matHeaderCellDef> Provider </th>
        <td mat-cell *matCellDef="let element"> {{element.provider}} </td>
    </ng-container>
    
    <ng-container matColumnDef="LocationName">
        <th mat-header-cell *matHeaderCellDef> Location </th>
        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>
    
    <ng-container matColumnDef="AppointmentType">
        <th mat-header-cell *matHeaderCellDef> Appt Type </th>
        <td mat-cell *matCellDef="let element"> {{element.appointmentType}} </td>
    </ng-container>
    
    <ng-container matColumnDef="AppointmentStatus">
        <th mat-header-cell *matHeaderCellDef> Appt Status </th>
        <td mat-cell *matCellDef="let element"> {{element.apptStatus}} </td>
    </ng-container>
    
    <ng-container matColumnDef="ChatStatus">
        <th mat-header-cell *matHeaderCellDef> Chat Status </th>
        <td mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element.chatStatus">
                <div *ngSwitchCase="chatStatusEnums.Closed"> 
                    <button (click)="previewChat(element.appointmentId)" class="table-icon-button"> <img src="assets\icon\Locked.svg"> </button>
                </div>
                <div 
                    *ngSwitchCase="chatStatusEnums.Open"
                    class="open-chat-icon"> 
                    <button (click)="previewChat(element.appointmentId)" class="table-icon-button"> <img src="assets\icon\Unlocked.svg"> </button>
                </div>
            </ng-container>
        </td>
    </ng-container>

    <tr class="table-header-row" mat-header-row *matHeaderRowDef="displayedColumns"> </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
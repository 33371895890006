import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface AlertConfiguration {
    alertText: string;
    alertTitle: string;
    closeTrigger?: Subject<boolean>;
}

@Component({
    selector: 'alert-popover',
    templateUrl: './alert-popover.component.html',
    styleUrls: ['./alert-popover.component.scss']
})
export class AlertPopoverComponent implements OnInit, OnDestroy {
    public static readonly defaultWidth = '20%';

    constructor(
        public dialogRef: MatDialogRef<AlertPopoverComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AlertConfiguration,
    ) { }

    public ngOnDestroy(): void {
        this.data.closeTrigger?.unsubscribe();
    }

    public ngOnInit(): void {
        this.data.closeTrigger?.subscribe( () => {
            this.closeWindow();
        });
    }

    public closeWindow() {
        this.dialogRef.close();
    }
}
